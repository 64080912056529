import AppBarHeader, { NavigationBarItemType } from "../../components/AppBarHeader";
import { BsArchive, BsFiletypeCsv, BsFillArchiveFill, BsFillBarChartLineFill, BsGear, BsGearFill, BsPeople, BsPrinter } from "react-icons/bs";
import {
    ButtonConfig,
    ButtonNewItem,
    ButtonNewItemMobile,
    ButtonTestMode,
    ButtonUserAdd,
    CardDialogContainer,
    ContainerBody,
    ContainerContent,
    RightPanelItems,
    RightPanelList,
    DropDownInfo,
    AutomaticData
} from "./styles";
import { FaCalendar, FaFlask, FaHistory, FaLock, FaLockOpen, FaPlug, FaPlus, FaThList } from "react-icons/fa";
import { IoMdListBox, IoMdMail, IoMdPersonAdd, IoMdSwitch } from "react-icons/io";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { RiFlowChart, RiTableFill } from "react-icons/ri";
import { useHistory, useRouteMatch } from 'react-router-dom';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';

import { Card } from "../../interfaces/Card";
import ConfigCard from "../../dialogs/ConfigCard";
import DialogAction from "../../dialogs/DialogAction";
import Dashboard from "../../components/Dashboard";
import { ElementProps } from "../../components/ElementBuilder";
import { FiTrash2 } from "react-icons/fi";
import FilterBar from "../../components/FilterBar";
import { FieldViewProps, FilterConditionItem, FilterFlowProps } from "../../components/FilterBar/FilterDialog";
import { Flow } from "../../interfaces/Flow"
import { IconList } from 'react-fa-icon-picker'
import InviteUser from "../../dialogs/InviteUser";
import Kanban from "../../components/Kanban";
import { KanbanConfigProps } from "../../components/Kanban";
import LayoutContainer from '../../components/LayoutContainer';
import { Link } from "react-router-dom";
import ListContainer from '../../components/ListContainer';
import NewAnswer from "../../dialogs/NewAnswer";
import NewElement from "../../dialogs/NewElement";
import { Panel } from "../../interfaces/Panel";
import RightPanel from "../../components/RightPanel";
import SwitchBase from "@material-ui/core/Switch";
import TableCange from "../../components/TableCange";
import { TiFlowMerge } from 'react-icons/ti';
import api from '../../services/api';
import getAccessControl from "../../middlewares/AccessControl";
import getKanbanConfig from "../../utils/KanbanConfig/getKanbanConfig";
import { stepsOnboardingFlow } from "../../utils/Steps";
import usePrevious from "../../hooks/usePrevius";
import { useToast } from '../../hooks/toast';
import { useTour } from "@reactour/tour";
import EmailBox from "../../components/EmailBox";
import Calendar from "../../components/Calendar";
import DropDownItem from "../../components/DropDownList/DropDownItem";
import DropDownDivider from "../../components/DropDownList/DropDownDivider";
import getCardFiltered from "../../middlewares/getCardFiltered";
import getCardSorted from "../../middlewares/getCardSorted";
import updateFormulaFromCard from "../../utils/Formula/updateFormulaFromCard";
import { FieldProps } from "../../components/Forms/Fields/FieldBuilder";
import getFields from "../../utils/getFields";
import { useAuth } from "../../hooks/auth";
import { useMediaQuery } from "react-responsive";
import { formatDateToDateRead } from "../../utils/formatDateToDateRead";
import { useReactToPrint } from "react-to-print";
import { TbDatabaseImport } from "react-icons/tb";
import ImporterData from "../../dialogs/ImporterData";
import { FormAnswer } from "../../interfaces/FormAnswer";
import { FormAnswerField } from "../../interfaces/FormAnswerField";
import ActivityTab from "./ActivityTab";
import InviteTeam from "../../dialogs/InviteTeam";
import StartTrial from "../../dialogs/StartTrial";
import { IoDuplicateOutline, IoFlash } from "react-icons/io5";
import { FlowConfigGroup, FlowConfigItem, FlowConfigItemDescription, FlowConfigItemIcon, FlowConfigItems, FlowConfigTitle } from "../../components/RightPanel/styles";
import Automation from "../../dialogs/Automations";
import { getFeatureControlByPlan } from "../../middlewares/FeatureControl";
import IconColorPicker from "../../components/IconColorPicker";
import CopyFlow from "../../dialogs/CopyFlow";

interface RouteParams {
    hash: string;
    origin?: string;
    id?: string;
}

export interface UpdateControlProps {
    random: number;
    card?: Card;
    cards?: Card[];
}

const navBarItemsDefault: NavigationBarItemType[] = [
    {
        index: 0,
        description: "Kanban",
        icon: RiTableFill,
        active: true,
        onClick: () => { }
    },
    {
        index: 1,
        description: "Lista",
        icon: FaThList,
        iconSize: '13.5',
        onClick: () => { }
    },
    {
        index: 4,
        description: "Calendário",
        icon: FaCalendar,
        iconSize: '12.5',
        typeUserAllowed: ["A", "M", "R", "I", "V"],
        onClick: () => { }
    },
    {
        index: 2,
        description: "Painel",
        icon: BsFillBarChartLineFill,
        typeUserAllowed: ["A", "M", "R", "I", "V"],
        onClick: () => { }
    },
    {
        index: 3,
        description: "Email",
        typeUserAllowed: ["A", "M"],
        icon: IoMdMail,
        onClick: () => { }
    },
    {
        index: 5,
        description: "Atividade",
        icon: FaHistory,
        iconSize: '12.5',
        typeUserAllowed: ["A"],
        onClick: () => { }
    },
]

const FlowList: React.FC = (props) => {

    const { params } = useRouteMatch<RouteParams>();
    const { addToast } = useToast();
    const { user } = useAuth();
    const history = useHistory();

    const [flow, setFlow] = useState<Flow>();
    const [panel, setPanel] = useState<Panel>();
    const [currentCard, setCurrentCard] = useState<Card>();
    const [filterFlow, setFilterFlow] = useState<FilterFlowProps>();
    const [initFilterFlow, setInitFilterFlow] = useState<FilterFlowProps>();
    const [redirectUrl, setRedirectUrl] = useState<boolean>(false);
    const [statusSavedPanel, setStatusSavedPanel] = useState<number>();
    const [selectedView, setSelectedView] = useState<number>();
    const [loadingPanel, setLoadingPanel] = useState<boolean>(false);
    const [updateControl, setUpdateControl] = useState<UpdateControlProps>({ random: 0 });
    const [firstUpdate, setFirstUpdate] = useState<boolean>(true);
    const [isOnboarding, setIsOnboarding] = useState<boolean>();
    const [openInviteTeam, setOpenInviteTeam] = useState<boolean>(false);
    const [openStartTrial, setOpenStartTrial] = useState<boolean>(false);

    //Dialogs control
    const [openRightPanel, setOpenRightPanel] = useState(false);
    const [openAutomation, setOpenAutomation] = useState(false);
    const [openNewAnswer, setOpenNewAnswer] = useState(false);
    const [openNewElement, setOpenNewElement] = useState(false);
    const [openConfigCard, setOpenConfigCard] = useState(false);
    const [openCard, setOpenCard] = useState(false);
    const [openInviteUser, setOpenInviteUser] = useState(false);
    const [loaderCard, setLoaderCard] = useState(true);
    const [kabanConfig, setKabanConfig] = useState<KanbanConfigProps>();
    const [navBarItems, setNavBarItems] = useState(navBarItemsDefault);
    const [itemNavBarSelected, setItemNavBarSelected] = useState<number>(0);

    const [stateDelFlow, setStateDelFlow] = useState(false);
    const [testModelFlow, setTestModelFlow] = useState(false);

    const prevConfigCard = usePrevious(openConfigCard);
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    const [loadingDeleteFlow, setLoadingDeleteFlow] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [openImporterData, setOpenImporterData] = useState(false);
    const printComponentRef = useRef(null);

    const [isArchived, setIsArchived] = useState<boolean>(false);

    const [nameFlow, setNameFlow] = useState<string>("");
    const [colorFlow, setColorFlow] = useState<string>("");
    const [iconFlow, setIconFlow] = useState<string>("");

    const [openIconColorPicker, setOpenIconColorPicker] = useState<boolean>(false);

    const [elementSelected, setElementSelected] = useState<ElementProps | undefined>(undefined);
    const [pendingSavingPanel, setPendingSavingPanel] = useState<boolean>(false);

    const [openCopyFlow, setOpenCopyFlow] = useState<boolean>(false);

    const { setIsOpen, setSteps } = useTour();

    const setArchiveMode = useCallback(async (isArchivedMode: boolean) => {
        setIsArchived(isArchivedMode);
        setUpdateControl({
            random: (Math.random() * 100) * -1
        });
    }, []);

    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current
    });

    const downloadCSVData = useCallback(async () => {

        try {

            if (flow !== undefined && flow.id_flow > 0
                && flow.flow_steps !== undefined && flow.flow_steps.length > 0) {

                const response = await api.get(`/card/by-flow`, {
                    params: {
                        flow_id: flow.id_flow,
                        isTestModel: testModelFlow !== undefined ? testModelFlow : undefined,
                        isWithTimeTracking: 'true',
                        isWithPreAnswer: 'true'
                    }
                });

                if (response.data !== null) {
                    let cardsApi: Card[] = response.data;

                    if (filterFlow !== undefined) {
                        cardsApi = getCardFiltered(cardsApi, user.id_user, filterFlow.conditions, filterFlow.searchText);
                        cardsApi = getCardSorted(cardsApi, filterFlow.orderBy);
                    }

                    let fields: FieldProps[] = [];
                    if (flow.form_init !== undefined && flow.flow_steps !== undefined) {
                        fields = getFields(flow.form_init, flow.flow_steps);
                    }

                    cardsApi = cardsApi.map((card) => {
                        return updateFormulaFromCard(card, fields);
                    });

                    //Create the object to export
                    let obj: object[] = [];
                    for (let index = 0; index < cardsApi.length; index++) {
                        const c = cardsApi[index];

                        let objCard: any = {
                            id_card: c.id_card,
                            title: c.title,
                            dt_created: c.dt_created,
                            dt_due: c.dt_due,
                            complete: c.complete,
                            flow_step: c.flow_step?.name,
                            flow: flow.name,
                            dt_due_string: c.dt_due_string,
                            user: c.user?.name,
                            status_dt_due: c.status_dt_due
                        }

                        if (filterFlow !== undefined && filterFlow.fieldView !== undefined && filterFlow.fieldView.length > 0) {

                            const fieldViewActive = filterFlow.fieldView.filter((fv) => fv.active);

                            if (fieldViewActive !== undefined && fieldViewActive.length > 0) {
                                for (let idxA = 0; idxA < fieldViewActive.length; idxA++) {
                                    const fv = fieldViewActive[idxA];

                                    let value: FormAnswerField[] | undefined = undefined;

                                    if (fv.id_field > 0) {

                                        if (c.form_answers !== undefined && c.form_answers.length > 0) {
                                            //Filter the form answer with the same form_id
                                            const formAnswer = c.form_answers.filter((answer: FormAnswer) => answer.form_id === fv.form_id);

                                            //Get the form answer with the biggest id
                                            let newerFormAnswer: FormAnswer | undefined = undefined;
                                            if (formAnswer !== undefined && formAnswer.length > 1) {
                                                newerFormAnswer = formAnswer.reduce((prev: FormAnswer, current: FormAnswer) => (prev.id_form_answer > current.id_form_answer) ? prev : current);
                                            } else {
                                                newerFormAnswer = formAnswer[0];
                                            }

                                            //Get the form answer field with the same field_id
                                            if (newerFormAnswer !== undefined && newerFormAnswer.form_answer_fields !== undefined && newerFormAnswer.form_answer_fields.length > 0) {
                                                value = newerFormAnswer.form_answer_fields.filter((answer: FormAnswerField) => answer.field_id === fv.id_field);
                                            }
                                        } else {
                                            value = undefined;
                                        }

                                        if (value !== undefined && value.length > 0) {

                                            let valueString = "";

                                            value.map((val, index) => {

                                                if (index >= 1) {
                                                    valueString += "; ";
                                                }

                                                if (val.valueString !== undefined) {
                                                    valueString += val.valueString;
                                                }

                                                return val;
                                            })

                                            objCard[fv.title] = valueString;
                                        } else {
                                            objCard[fv.title] = "";
                                        }

                                    }

                                }
                            }

                        }

                        obj.push(objCard);
                    }

                    const csv = Papa.unparse(obj);
                    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
                    saveAs(blob, flow.name + '.csv');

                }

            }
        } catch (error) {
            addToast({
                type: 'error',
                title: 'Erro ao carregar os dados do Fluxo',
                description: 'Ocorreu ao tentar carregar os dados do Fluxo!',
            });
        }
    }, [addToast, flow, testModelFlow, filterFlow, user]);

    const getApiPanelData = useCallback(async (idPanel?: number, conditions?: FilterConditionItem[]) => {

        if ((panel !== undefined || idPanel !== undefined) && flow !== undefined && flow.id_flow !== undefined) {

            setLoadingPanel(true);

            await api.post(`/panel/data/v2`, {
                id_panel: panel !== undefined ? panel.id_panel : idPanel,
                flow_id: flow.id_flow,
                isTestMode: testModelFlow,
                conditions: conditions !== undefined ? conditions : undefined
            }).then(response => {
                if (response.data !== null) {

                    const respPanel: Panel = response.data;

                    if (respPanel !== undefined) {
                        setPanel((p) => {
                            p?.elements?.map((e) => {

                                const arrData = respPanel.elements?.filter((elF) => elF.hash === e.hash);

                                if (arrData !== undefined && arrData.length > 0) {
                                    e.dataElement = arrData[0].dataElement;
                                }

                                return e;
                            })

                            return p;
                        });
                    }

                }
                setLoadingPanel(false);
            }).catch(error => {
                setLoadingPanel(false);
                addToast({
                    type: 'error',
                    title: 'Erro ao carregar os dados do Painel',
                    description: 'Ocorreu ao tentar carregar os dados do Painel!',
                });
            });

        }

    }, [addToast, flow, panel, testModelFlow]);

    const getApiPanel = useCallback(async () => {

        if (flow !== undefined && flow.id_flow > 0) {

            api.get(`/panel/by-flow`, {
                params: {
                    flow_id: flow.id_flow
                }
            }).then(response => {
                if (response.data !== null) {

                    const respPanel: Panel = response.data;

                    if (respPanel !== undefined) {
                        setPanel(respPanel);
                    }

                }

            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao carregar o Painel do Fluxo',
                    description: 'Ocorreu ao tentar abrir o painel do Fluxo!',
                });
            });

        }

    }, [addToast, flow]);

    const onUpdatePanel = useCallback(async (panel: Panel) => {

        setPanel(panel);

    }, []);

    const onUpdateStatusSavedPanel = useCallback(async (status: number) => {

        setStatusSavedPanel(status);

    }, []);

    const savePanel = useCallback(async (panelBase: Panel, withLoadData?: boolean) => {

        onUpdateStatusSavedPanel(3);

        if (panelBase !== undefined && panelBase.pendingUpd) {

            await api
                .post('/panel', panelBase)
                .then(response => {

                    setPendingSavingPanel(false);
                    onUpdateStatusSavedPanel(1);

                    if (withLoadData) {
                        getApiPanel();
                    }

                }).catch(error => {
                    setPendingSavingPanel(false);
                    onUpdateStatusSavedPanel(1);

                    addToast({
                        type: 'error',
                        title: 'Erro ao inserir um novo item [1]',
                        description: 'Ocorreu um erro ao inserir o registro!',
                    });
                });
        }

    }, [addToast, onUpdateStatusSavedPanel, getApiPanel]);

    const onAddNewElement = useCallback(async (element: ElementProps | undefined) => {

        if (element === undefined) {
            setElementSelected(undefined);
        }

        if (panel !== undefined && element !== undefined) {

            let isEditing = false;

            //Check if the element already exists
            if (panel.elements !== undefined && panel.elements !== null && panel.elements.length > 0) {
                const arrElements = panel.elements.find((el) => el.hash === element.hash);

                if (arrElements !== undefined) {
                    isEditing = true;
                }
            }

            if (isEditing) {

                let newPanel = panel;

                let newArrElements = panel.elements !== undefined ? panel.elements : [];

                newArrElements = newArrElements.map((el) => {
                    if (el.hash === element.hash) {
                        return element;
                    } else {
                        return el;
                    }
                });

                newPanel.elements = newArrElements;
                newPanel.pendingUpd = true;

                setPanel(newPanel);

                savePanel(newPanel, true);

            } else {

                let newArrElements = panel.elements !== undefined ? panel.elements : [];

                newArrElements.push(element);

                setPanel(panel => {

                    if (panel !== undefined) {
                        return {
                            ...panel,
                            pendingUpd: true,
                            pendingUpdData: true,
                            elements: newArrElements
                        }
                    } else {
                        return undefined;
                    }

                });

            }

            setOpenNewElement(false);

        }

    }, [panel, savePanel]);

    const onCopyElement = useCallback(async (element: ElementProps) => {

        //Create the element hash
        const dateNowTime = new Date().getTime();
        var hashLib = require('object-hash');

        const newHash = await hashLib(dateNowTime + element.type + flow?.id_flow + "copy");

        let newElement: ElementProps = {
            hash: newHash,
            type: element.type,
            title: String("Cópia de " + element.title).substring(0, 44),
            help_text: element.help_text,
            width: element.width,
            height: element.height,
            axis_x_field_id: element.axis_x_field_id,
            axis_y_field_id: element.axis_y_field_id,
            calc_function: element.calc_function,
            sort_by: element.sort_by,
            embed: element.embed,
            axis_x_field_type: element.axis_x_field_type,
            axis_y_field_type: element.axis_y_field_type,
            group_by_field_id: element.group_by_field_id,
            group_by_field_type: element.group_by_field_type,
            group_by_field_date: element.group_by_field_date,
            show_time: element.show_time,
            group_date: element.group_date,
            color: element.color,
            filter: element.filter,
        };

        onAddNewElement(newElement);

    }, [flow, onAddNewElement]);

    const onEditElement = useCallback(async (element: ElementProps) => {

        setElementSelected(element);
        setOpenNewElement(true);

    }, []);

    const onAddElementDialog = useCallback(() => {
        setElementSelected(undefined);
        setOpenNewElement(true);
    }, []);

    const getApiCard = useCallback(async (id_card: number, keepCardOpen?: boolean) => {

        if (id_card !== undefined && flow?.id_flow !== undefined) {

            await api.get(`/card/v2`, {
                params: {
                    id_card: id_card,
                    flow_id: flow?.id_flow,
                    isTestModel: testModelFlow !== undefined ? testModelFlow : undefined,
                    isIgnoringDelArchStatus: true,
                    isWithPreAnswer: true,
                    isWithTimeTracking: true
                }
            }).then(response => {
                if (response.data !== null) {

                    const newCard: Card = response.data;

                    setUpdateControl({
                        random: (Math.random() * 100) * -1,
                        card: newCard
                    });

                    if (keepCardOpen) {
                        setCurrentCard(newCard);
                        setOpenCard(true);
                    }

                }
            })

        }

    }, [flow?.id_flow, testModelFlow]);

    const getApiCardStart = useCallback(async (respFlow: Flow) => {

        api.get(`/card/v2`, {
            params: {
                id_card: params.id,
                flow_id: respFlow.id_flow,
                company_id: respFlow.company_id,
                isTestMode: testModelFlow !== undefined ? testModelFlow : undefined,
                isWithTimeTracking: true
            }
        }).then(response => {
            if (response.data !== null) {

                const cardApi: Card = response.data;

                if (cardApi !== undefined && cardApi !== null) {

                    setCurrentCard(cardApi);
                    setOpenCard(true);
                    setRedirectUrl(true);

                    const kanbanConfig: KanbanConfigProps = getKanbanConfig(respFlow);

                    setKabanConfig({
                        fieldTitle: kanbanConfig.fieldTitle,
                        fieldHighlight: kanbanConfig.fieldHighlight,
                        fieldFormInit: kanbanConfig.fieldFormInit,
                        fieldFormAll: kanbanConfig.fieldFormAll
                    })

                }
            }

        });

    }, [params.id, testModelFlow]);

    const changeNavBarItem = useCallback(async (index: number) => {

        setUpdateControl({ random: (Math.random() * 100) * -1 });

        setItemNavBarSelected(index);

        setNavBarItems(oldArr =>
            oldArr.map((item, idx) => {

                if (item.index === index) {
                    item.active = true;
                } else {
                    item.active = false;
                }

                return item;
            })
        );

        if (params.hash !== undefined && index >= 0) {
            //Update the preference from currentTab
            api.post('/flow-user-preference', {
                hash: params.hash,
                currentTab: index
            })
        }

    }, [params.hash]);

    const handleCloseStartTrial = useCallback(async () => {

        if (params.origin === "onboarding" && !isMobile) {

            setSteps(stepsOnboardingFlow);
            setIsOpen(true);
            history.replace("/flow/" + params.hash);

        }

        setOpenStartTrial(false);

    }, [history, params.hash, params.origin, setSteps, setIsOpen, isMobile]);

    const getApiFlow = useCallback(async (withLoading: boolean) => {

        setLoading(true);

        await api.get(`/flow`, {
            params: {
                id_flow: params.hash !== undefined && params.hash.length <= 11 ? params.hash : undefined,
                hash: params.hash !== undefined && params.hash.length > 11 ? params.hash : undefined,
                withSteps: true,
                withCards: false,
                removeStepsCondicionals: true
            }
        }).then(response => {
            if (response.data !== null) {

                const respFlow: Flow = response.data;

                if (respFlow !== undefined) {

                    //Replace them id instead hash
                    if (params.hash !== undefined && params.hash.length <= 11) {
                        history.replace("/flow/" + respFlow.hash);
                        params.hash = respFlow.hash;
                    }

                    if (respFlow.currentTab !== undefined && respFlow.currentTab !== null) {
                        changeNavBarItem(respFlow.currentTab);
                    } else {
                        changeNavBarItem(0);
                    }

                    setFlow(respFlow);

                    //Update the name of the flow
                    setNameFlow(respFlow.name);
                    setColorFlow(respFlow.color !== undefined ? respFlow.color : "");
                    setIconFlow(respFlow.icon !== undefined ? respFlow.icon : "");

                    //Set the test mode, just remove when come to see a card
                    if (respFlow.isTestMode === "T" && params.origin !== "card") {
                        setTestModelFlow(true);
                    }

                    if (params.origin === "card" && params.id !== undefined) {

                        getApiCardStart(respFlow);

                    } else if (params.origin === "onboarding") {

                        let isStartTrial = false;

                        //Valid the query url checking if ?action=start-trial
                        const action = new URLSearchParams(window.location.search).get('action');
                        if (action !== undefined && action !== null && user !== undefined) {
                            if (action === "start-trial" && !user.isStartedTrial) {
                                isStartTrial = true;
                            }
                        }

                        if (isStartTrial) {
                            setOpenStartTrial(true);
                        } else if (!isMobile) {
                            setSteps(stepsOnboardingFlow);
                            setIsOpen(true);
                            history.replace("/flow/" + params.hash);
                        }

                    } else if (params.origin === "onboarding-scratch") {

                        let isStartTrial = false;

                        //Valid the query url checking if ?action=start-trial
                        const action = new URLSearchParams(window.location.search).get('action');
                        if (action !== undefined && action !== null && user !== undefined) {
                            if (action === "start-trial" && !user.isStartedTrial) {
                                isStartTrial = true;
                            }
                        }

                        if (isStartTrial) {
                            setOpenStartTrial(true);
                        }

                    }

                    if (respFlow.currentView !== undefined && respFlow.currentView !== null) {
                        setSelectedView(respFlow.currentView);

                        //Preserve the main filter
                        if (respFlow.schema_view !== undefined) {
                            const newFieldView: FieldViewProps[] = JSON.parse(respFlow.schema_view);

                            if (newFieldView !== undefined && newFieldView !== null && newFieldView.length > 0) {
                                setInitFilterFlow({ fieldView: newFieldView, conditions: [], orderBy: [] });
                            }
                        }
                    } else if (respFlow.currentFilter !== undefined && respFlow.currentFilter !== null) {

                        let newFilterFlow = JSON.parse(respFlow.currentFilter) as FilterFlowProps;
                        newFilterFlow.fieldView = respFlow.schema_view !== undefined ? JSON.parse(respFlow.schema_view) : undefined;

                        setInitFilterFlow(newFilterFlow);
                    }

                    setLoaderCard(false);

                }
            }
            setFirstUpdate(false);
            setLoading(false);
        }).catch(error => {
            console.log(error);
            history.push('/flow-list');
            setLoading(false);
            addToast({
                type: 'error',
                title: 'Erro ao abrir o Fluxo',
                description: 'Ocorreu ao tentar abrir o Fluxo!',
            });
        });

    }, [addToast, params, history, getApiCardStart, changeNavBarItem, setSteps, setIsOpen, user, isMobile])

    const refreshPage = useCallback(async () => {

        getApiFlow(false);

    }, [getApiFlow]);

    const dialogNewAnswerSubmmit = useCallback(async () => {

    }, []);

    const dialogNewAnswerOpen = useCallback(async () => {
        setOpenNewAnswer(true);
    }, []);

    const dialogNewAnswerClose = useCallback(async (updateData: boolean, behavior: string, id_card?: number) => {
        setOpenNewAnswer(false);

        //Invite the team
        if (isOnboarding) {
            const callInviteUsers = localStorage.getItem("@wendata:onboarding-invite-team");
            if (callInviteUsers !== undefined && callInviteUsers !== null) {
                if (callInviteUsers === "false") {
                    //Open the invite user dialog
                    setOpenInviteTeam(true);

                    //Set the onboarding invite team as true
                    localStorage.setItem('@wendata:onboarding-invite-team', "true");
                }
            }
        }

        if (updateData && id_card !== undefined) {            
            getApiCard(id_card, behavior === "2" ? true : false); // Behavior 2 is to keep the card open
        } else if (updateData) {
            setUpdateControl({
                random: (Math.random() * 100) * -1
            });
        }

        if (updateData && behavior !== undefined && behavior === "3") { // Open the new answer dialog
            dialogNewAnswerOpen();
        }

    }, [getApiCard, isOnboarding, dialogNewAnswerOpen]);

    const rightPanelClose = useCallback(async () => {
        setOpenRightPanel(false);
    }, []);

    const configCardClose = useCallback(async () => {
        setOpenConfigCard(false);
    }, []);

    const cardClose = useCallback(async (updateData: boolean, id_card?: number, keepCardOpen?: boolean) => {
        setOpenCard(false);

        setCurrentCard(undefined);

        if (redirectUrl) {
            setRedirectUrl(false);
            history.replace("/flow/" + flow?.hash);
        }

        if (updateData && id_card !== undefined) {

            getApiCard(id_card, keepCardOpen);

        } else {
            if (updateData && id_card === undefined) {
                setUpdateControl({
                    random: (Math.random() * 100) * -1
                });
            }
        }

    }, [flow, history, redirectUrl, getApiCard]);

    const cardOpen = useCallback(async (card: Card, kanban_config?: KanbanConfigProps) => {
        setKabanConfig(kanban_config);
        setCurrentCard(card);
        setOpenCard(true);

        if (params.origin === "onboarding") {

            //setSteps(stepsOnboardingFlow);
            //setIsOpen(true)

        }

    }, [params.origin]);

    const configCardOpen = useCallback(async () => {
        if (openRightPanel) {
            setOpenRightPanel(false);
        }
        setOpenConfigCard(true);
    }, [openRightPanel]);

    const dialogDelFlowClose = useCallback(async () => {
        setStateDelFlow(false);
    }, []);

    const dialogDelFlowOpen = useCallback(async () => {
        setStateDelFlow(true);
    }, []);

    const dialogDelFlowSubmmit = useCallback(async () => {

        if (flow !== undefined) {

            setLoadingDeleteFlow(true);
            await api
                .delete('/flow?id_flow=' + flow?.id_flow)
                .then(response => {

                    setStateDelFlow(false);

                    history.push('/flow-list');

                    setLoadingDeleteFlow(false);
                }).catch(error => {
                    setLoadingDeleteFlow(false);
                    addToast({
                        type: 'error',
                        title: 'Erro ao deletar o Fluxo',
                        description: error.response.data.message,
                    });
                });

        } else {

            addToast({
                type: 'error',
                title: 'Erro ao deletar o Fluxo',
                description: 'Ocorreu um erro ao deletar o Fluxo!',
            });

        }

    }, [history, addToast, flow]);

    const onRowClicked = useCallback(async (row: Card) => {

        if (row.deleted !== "S") {
            setCurrentCard(row);
            setOpenCard(true);
        }

    }, []);

    const onTestModeClicked = useCallback(async (evt: any) => {

        setTestModelFlow(Boolean(evt.currentTarget.checked));

        //Update the api preference flow
        if (typeof evt.currentTarget.checked === `boolean`) {
            await api.post('/flow-user-preference', {
                hash: params.hash,
                isTestMode: Boolean(evt.currentTarget.checked) ? "T" : "N"
            })
        }

    }, [params.hash]);

    const handleTestMode = useCallback(async (active: boolean) => {

        setTestModelFlow(active);

        //Update the api preference flow
        await api.post('/flow-user-preference', {
            hash: params.hash,
            isTestMode: active ? "T" : "N"
        })


    }, [params.hash]);

    const onFilterField = useCallback(async () => {

        configCardOpen();

    }, [configCardOpen]);

    const onFilterFlow = useCallback(async (filterFlow: FilterFlowProps, openedDialog: boolean) => {

        if (openedDialog === false) {

            if (filterFlow?.searchText !== undefined && filterFlow?.searchText !== "") {
                setFilterFlow({ fieldView: filterFlow.fieldView, conditions: filterFlow.conditions, orderBy: filterFlow.orderBy, searchText: filterFlow?.searchText });
            } else {
                setFilterFlow({ fieldView: filterFlow.fieldView, conditions: filterFlow.conditions, orderBy: filterFlow.orderBy });
            }

            //Save the filter flow as the user preference
            await api.post('/flow-user-preference', {
                hash: params.hash,
                currentView: filterFlow.flow_view_id,
                currentFilter: filterFlow.flow_view_id !== undefined ? undefined : JSON.stringify({ conditions: filterFlow.conditions, orderBy: filterFlow.orderBy } as FilterFlowProps)
            });

            //If is the panel view, update the panel data
            if (itemNavBarSelected === 2) {
                let newFilterFlow = filterFlow;

                if (newFilterFlow !== undefined) {
                    newFilterFlow.conditions = newFilterFlow.conditions.map((condition) => {
                        condition.fields = [];
                        condition.comparators = [];

                        return condition;
                    });
                }

                getApiPanelData(undefined, newFilterFlow.conditions);
            }

        }

    }, [params.hash, getApiPanelData, itemNavBarSelected]);

    const goToConfigFlow = useCallback(async () => {

        if (params.hash !== undefined) {
            if (getAccessControl(15, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "")) {
                history.push("/flow/1/" + params.hash + "/edit");
            }
        }

    }, [history, params.hash, flow]);

    const handleSaveNameFlow = useCallback(async () => {

        if (flow !== undefined && nameFlow !== undefined && nameFlow !== null && nameFlow.trim() !== "" && nameFlow !== flow.name) {

            await api.post('/flow', {
                id_flow: flow.id_flow,
                name: nameFlow
            }).then(response => {
                if (response.data !== null) {
                    addToast({
                        type: 'success',
                        title: 'Nome do Fluxo salvo!',
                        description: 'O nome do Fluxo foi salvo com sucesso!',
                    });
                }
            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao salvar o nome do Fluxo',
                    description: 'Ocorreu um erro ao salvar o nome do Fluxo!',
                });
            });

        }

    }, [addToast, flow, nameFlow]);

    const handleSaveColorIcon = useCallback(async () => {

        if (flow !== undefined && colorFlow !== undefined && iconFlow !== undefined && colorFlow !== "" && iconFlow !== "" && (colorFlow !== flow.color || iconFlow !== flow.icon)) {

            await api.post('/flow', {
                id_flow: flow.id_flow,
                color: colorFlow,
                icon: iconFlow
            }).then(response => {
                if (response.data !== null) {
                    addToast({
                        type: 'success',
                        title: 'Cor e ícone do Fluxo salvos!',
                        description: 'A cor e o ícone do Fluxo foram salvos com sucesso!',
                    });
                }
            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao salvar a cor e o ícone do Fluxo',
                    description: 'Ocorreu um erro ao salvar a cor e o ícone do Fluxo!',
                });
            });

        }

    }, [addToast, flow, colorFlow, iconFlow]);

    const handleOpenIconColorPicker = useCallback(async () => {

        setOpenIconColorPicker(true);

    }, []);

    const handleCloseIconColorPicker = useCallback(() => {

        setOpenIconColorPicker(false);

        handleSaveColorIcon();

    }, [handleSaveColorIcon]);

    useEffect(() => {

        //Valid if is onboarding
        const isOnboarding = localStorage.getItem("@wendata:onboarding");
        if (isOnboarding !== undefined && isOnboarding !== null) {
            if (isOnboarding === "true") {
                setIsOnboarding(true);
            }
        }

        //First Update
        getApiFlow(true);

    }, [getApiFlow])

    useEffect(() => {

        //Update when change any dialog state
        if (prevConfigCard) {
            getApiFlow(false);
        }

    }, [getApiFlow, prevConfigCard]);

    useEffect(() => {

        setNavBarItems(oldArr =>
            oldArr.map((item) => {

                if (item.description === "Kanban") {

                    item.onClick = () => changeNavBarItem(0);

                } else if (item.description === "Lista") {

                    item.onClick = () => changeNavBarItem(1);

                } else if (item.description === "Painel") {

                    item.onClick = () => changeNavBarItem(2);

                } else if (item.description === "Email") {

                    item.onClick = () => changeNavBarItem(3);

                } else if (item.description === "Calendário") {

                    item.onClick = () => changeNavBarItem(4);

                } else if (item.description === "Atividade") {

                    item.onClick = () => changeNavBarItem(5);

                }

                return item;
            })
        )

    }, [params.origin, changeNavBarItem]);

    return (
        <LayoutContainer>
            <title>{flow?.name !== undefined ? flow.name : "Fluxo"} | Cange</title>
            <ListContainer className="tour-flow-1">

                {/* Header Page */}
                <AppBarHeader
                    key={"app-header-flow"}
                    title={flow !== undefined ? flow.name : "Fluxo"}
                    titleDynamic={nameFlow}
                    setTitleDynamic={!loading ? setNameFlow : undefined}
                    onBlueTitleDynamic={!loading ? handleSaveNameFlow : undefined}
                    maxLenTitle={150}
                    disabledTitleDynamic={!getAccessControl(15, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "")}
                    icon={TiFlowMerge}
                    iconDynamic={iconFlow !== undefined ? iconFlow as IconList : "FaEllipsisH" as IconList}
                    iconDynamicColor={colorFlow}
                    navBarItems={navBarItems}
                    itemNavBar={itemNavBarSelected}
                    typeUser={flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : ""}
                    isActiveDropDownMenu={true}
                    onClickIconColorPicker={!loading ? handleOpenIconColorPicker : undefined}
                    dropDownMenuChildren={
                        <>
                            {getAccessControl(15, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ?
                                <DropDownItem title="Editar configurações do fluxo" icon={BsGear} color={"#f23b5c"} onClick={() => goToConfigFlow()} /> :
                                <></>
                            }
                            {getAccessControl(5, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ?
                                <DropDownItem title="Gerenciar o acesso ao fluxo" icon={BsPeople} color={"#9e37ed"} onClick={() => setOpenInviteUser(!openInviteUser)} /> :
                                <></>
                            }
                            {getAccessControl(22, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ?
                                <DropDownItem title="Ver cartões arquivados" icon={BsArchive} color={"#4d4d4d"} onClick={() => setArchiveMode(true)} /> :
                                <></>
                            }
                            {getAccessControl(70, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ?
                                <>
                                    <DropDownDivider />
                                    <DropDownItem title="Exportar os dados" icon={BsFiletypeCsv} color={"#9e37ed"} onClick={getFeatureControlByPlan(2, user.company) ? () => downloadCSVData() : () => { }} isPaidFeature={!getFeatureControlByPlan(2, user.company)} idealPlan="Plano Plus" />
                                    <DropDownItem title="Imprimir esta visualização" icon={BsPrinter} color={"#9e37ed"} onClick={() => handlePrint()} />
                                </> :
                                <></>
                            }
                            {getAccessControl(92, user.type) ?
                                <>
                                    <DropDownDivider />
                                    <DropDownItem title="Duplicar este fluxo" icon={IoDuplicateOutline} color={"#9e37ed"} onClick={() => setOpenCopyFlow(true)} />
                                </> :
                                <></>
                            }
                            <DropDownDivider />
                            {flow !== undefined && flow.user_creator !== undefined ?
                                <DropDownInfo style={{ paddingBottom: '0px' }}>
                                    <span>Criado por:</span> {flow.user_creator.name}
                                </DropDownInfo> :
                                <></>
                            }
                            {flow !== undefined && flow.dt_created !== undefined ?
                                <DropDownInfo style={{ paddingBottom: '0px' }}>
                                    <span>Criado em:</span> {flow.dt_created !== undefined ? formatDateToDateRead(new Date(flow.dt_created)) : ""}
                                </DropDownInfo> :
                                <></>
                            }
                            {flow !== undefined && flow.id_flow !== undefined ?
                                <DropDownInfo style={{ paddingBottom: '0px' }}>
                                    <span>Identificador:</span> <AutomaticData>{flow?.id_flow}</AutomaticData>
                                </DropDownInfo> :
                                <></>
                            }
                            {flow !== undefined && flow.isPrivate !== undefined && flow.isPrivate === '1' ?
                                <DropDownInfo>
                                    <FaLock style={{ marginRight: '5px', color: 'gray' }} /> Fluxo privado
                                </DropDownInfo> :
                                <DropDownInfo>
                                    <FaLockOpen style={{ marginRight: '5px', color: 'gray' }} /> Fluxo público
                                </DropDownInfo>
                            }
                        </>
                    }
                >

                    {getAccessControl(23, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ?
                        testModelFlow && (
                            <ButtonTestMode>
                                <label>
                                    Modo Teste
                                </label>
                                <SwitchBase
                                    name={"testModeAppBar"}
                                    checked={testModelFlow}
                                    onChange={(evt) => onTestModeClicked(evt)}
                                />
                            </ButtonTestMode>
                        ) : <></>
                    }

                    {/* Novo Item */}
                    {getAccessControl(12, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ?
                        <>
                            <ButtonNewItem
                                icon={FaPlus}
                                className="new-item-btn"
                                type="button"
                                color="#f23b5c"
                                height="36px"
                                margin="0px 15px 0px 0px"
                                onClick={dialogNewAnswerOpen}
                            >
                                {
                                    flow?.button_add_name !== undefined &&
                                        flow?.button_add_name !== null &&
                                        flow?.button_add_name !== "" ?
                                        flow?.button_add_name : "Novo Item"
                                }
                            </ButtonNewItem>

                            <ButtonNewItemMobile className="new-item-btn" type="button" onClick={() => dialogNewAnswerOpen()}>
                                <FaPlus />
                            </ButtonNewItemMobile>

                            <NewAnswer
                                open={openNewAnswer}
                                onSubmmit={dialogNewAnswerSubmmit}
                                onClose={dialogNewAnswerClose}
                                flow={flow}
                                testModel={testModelFlow}
                                typeUser={flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : ""}
                            />
                        </> : <></>
                    }

                    {flow !== undefined && flow.id_flow !== undefined && flow.name !== undefined && (
                        <CopyFlow
                            open={openCopyFlow}
                            onClose={() => setOpenCopyFlow(false)}
                            id_flow={flow.id_flow}
                            name_flow={flow.name}
                        />
                    )}

                    <InviteTeam
                        open={openInviteTeam}
                        onClose={() => setOpenInviteTeam(false)}
                    />

                    <StartTrial
                        open={openStartTrial}
                        onClose={handleCloseStartTrial}
                    />

                    {/* Usuários */}
                    {getAccessControl(5, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ?
                        <>
                            <ButtonUserAdd
                                icon={IoMdPersonAdd}
                                type="button"
                                color="#4680B8"
                                height="36px"
                                margin="0px 15px 0px 0px"
                                onClick={() => setOpenInviteUser(!openInviteUser)}
                            >
                                Convidar
                            </ButtonUserAdd>

                            {openInviteUser && (
                                <InviteUser
                                    open={openInviteUser}
                                    onClose={() => setOpenInviteUser(false)}
                                    flow={flow}
                                    typeUser={flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : ""}
                                    title="Convide membros para o fluxo"
                                />
                            )}
                        </> : <></>
                    }

                    {/* Configurar */}
                    {getAccessControl(13, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ?
                        <>
                            <ButtonConfig
                                icon={BsGearFill}
                                type="button"
                                color="#f4f4f4"
                                height="36px"
                                margin="0px 15px 0px 0px"
                                color_text="gray"
                                onClick={() => setOpenRightPanel(true)}
                            >
                            </ButtonConfig>
                            <RightPanel
                                open={openRightPanel}
                                closePanel={() => rightPanelClose()}
                                title="">

                                <RightPanelItems>
                                    <RightPanelList>

                                        <FlowConfigGroup>
                                            {getAccessControl(15, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ||
                                                getAccessControl(19, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ||
                                                getAccessControl(20, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ?
                                                <FlowConfigTitle>
                                                    Configurações do fluxo
                                                </FlowConfigTitle> : <></>
                                            }

                                            <FlowConfigItems>

                                                {getAccessControl(15, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ?
                                                    <Link to={'/flow/1/' + params.hash + "/edit"} style={{ textDecoration: 'none' }}>
                                                        <FlowConfigItem color="#f23b5c">
                                                            <FlowConfigItemIcon color="#f23b5c">
                                                                <BsGearFill />
                                                            </FlowConfigItemIcon>
                                                            <FlowConfigItemDescription>
                                                                <h3>Configurações gerais</h3>
                                                                <span>Configure as informações gerais deste fluxo</span>
                                                            </FlowConfigItemDescription>
                                                        </FlowConfigItem>
                                                    </Link> : <></>
                                                }

                                                {getAccessControl(19, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ?
                                                    <Link to={'/flow/2/' + params.hash + "/edit"} style={{ textDecoration: 'none' }}>
                                                        <FlowConfigItem color="#61bd4f">
                                                            <FlowConfigItemIcon color="#61bd4f">
                                                                <IoMdListBox />
                                                            </FlowConfigItemIcon>
                                                            <FlowConfigItemDescription>
                                                                <h3>Formulário inicial</h3>
                                                                <span>Edite os campos do formulário inicial</span>
                                                            </FlowConfigItemDescription>
                                                        </FlowConfigItem>
                                                    </Link> : <></>
                                                }

                                                {getAccessControl(20, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ?
                                                    <Link to={'/flow/3/' + params.hash + "/edit"} style={{ textDecoration: 'none' }}>
                                                        <FlowConfigItem color="#9e37ed">
                                                            <FlowConfigItemIcon color="#9e37ed">
                                                                <RiFlowChart />
                                                            </FlowConfigItemIcon>
                                                            <FlowConfigItemDescription>
                                                                <h3>Etapas do fluxo</h3>
                                                                <span>Defina as configurações de cada etapa</span>
                                                            </FlowConfigItemDescription>
                                                        </FlowConfigItem>
                                                    </Link> : <></>
                                                }

                                            </FlowConfigItems>

                                        </FlowConfigGroup>

                                        <FlowConfigGroup style={{ marginTop: '20px' }}>
                                            {getAccessControl(79, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ||
                                                getAccessControl(80, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ||
                                                getAccessControl(21, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ||
                                                getAccessControl(73, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ?
                                                <FlowConfigTitle>
                                                    Ferramentas
                                                </FlowConfigTitle> :
                                                <></>
                                            }

                                            <FlowConfigItems>

                                                {getAccessControl(79, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ?
                                                    <FlowConfigItem key="it-auto" color="#00bcd4" onClick={() => {
                                                        setOpenAutomation(true);
                                                        setOpenRightPanel(false);
                                                    }}>
                                                        <FlowConfigItemIcon color="#00bcd4">
                                                            <IoFlash />
                                                        </FlowConfigItemIcon>
                                                        <FlowConfigItemDescription>
                                                            <h3>Automações</h3>
                                                            <span>Crie automações para simplificar seu trabalho</span>
                                                        </FlowConfigItemDescription>
                                                    </FlowConfigItem> :
                                                    <></>
                                                }

                                                {getAccessControl(80, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ?
                                                    <FlowConfigItem color="#00bcd4">
                                                        <FlowConfigItemIcon color="#00bcd4">
                                                            <FaPlug />
                                                        </FlowConfigItemIcon>
                                                        <FlowConfigItemDescription>
                                                            <h3>Integrações<span>Plano Enterprise</span></h3>
                                                            <span>Desenvolva integrações com outros sistemas</span>
                                                        </FlowConfigItemDescription>
                                                    </FlowConfigItem> :
                                                    <></>
                                                }

                                                {getAccessControl(21, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ?
                                                    <FlowConfigItem color="#ff8c2f" onClick={() => configCardOpen()}>
                                                        <FlowConfigItemIcon color="#ff8c2f">
                                                            <IoMdSwitch />
                                                        </FlowConfigItemIcon>
                                                        <FlowConfigItemDescription>
                                                            <h3>Configurar exibição</h3>
                                                            <span>Configure a exibição do cartão nas conexões</span>
                                                        </FlowConfigItemDescription>
                                                    </FlowConfigItem> : <></>
                                                }

                                                {getAccessControl(73, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") && flow !== undefined && flow.form_init_id !== undefined ?
                                                    <>
                                                        <FlowConfigItem color="#795548" onClick={() => {
                                                            setOpenImporterData(true);
                                                            setOpenRightPanel(false);
                                                        }}>
                                                            <FlowConfigItemIcon color="#795548">
                                                                <TbDatabaseImport />
                                                            </FlowConfigItemIcon>
                                                            <FlowConfigItemDescription>
                                                                <h3>Importar dados</h3>
                                                                <span>Importe dados a partir de um arquivo</span>
                                                            </FlowConfigItemDescription>
                                                        </FlowConfigItem>
                                                        {openImporterData ?
                                                            <ImporterData
                                                                open={openImporterData}
                                                                onClose={() => {
                                                                    setOpenImporterData(false);
                                                                    setUpdateControl({
                                                                        random: (Math.random() * 100) * -1
                                                                    });
                                                                }}
                                                                form_id={flow?.form_init_id}
                                                                flow_id={flow.id_flow}
                                                            /> :
                                                            <> </>
                                                        }
                                                    </>
                                                    : <></>
                                                }

                                            </FlowConfigItems>

                                        </FlowConfigGroup>

                                        <FlowConfigGroup style={{ marginTop: '20px' }}>
                                            {getAccessControl(22, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ||
                                                getAccessControl(23, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ?
                                                <FlowConfigTitle>
                                                    Dados
                                                </FlowConfigTitle> :
                                                <></>
                                            }

                                            <FlowConfigItems>

                                                {getAccessControl(22, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ?
                                                    <FlowConfigItem color="#4d4d4d" onClick={() => {
                                                        setArchiveMode(true);
                                                        setOpenRightPanel(false);
                                                    }}>
                                                        <FlowConfigItemIcon color="#4d4d4d">
                                                            <BsFillArchiveFill />
                                                        </FlowConfigItemIcon>
                                                        <FlowConfigItemDescription>
                                                            <h3>Cartões arquivados</h3>
                                                            <span>Visualize seus cartões arquivados</span>
                                                        </FlowConfigItemDescription>
                                                    </FlowConfigItem> : <></>
                                                }

                                                {getAccessControl(23, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ?
                                                    <FlowConfigItem color="#f23b5c" onClick={() => handleTestMode(!testModelFlow)}>
                                                        <FlowConfigItemIcon color="#f23b5c">
                                                            <FaFlask />
                                                        </FlowConfigItemIcon>
                                                        <FlowConfigItemDescription>
                                                            {!testModelFlow ? <h3>Ativar modo teste</h3> : <h3>Desativar modo teste</h3>}
                                                            {!testModelFlow ? <span>Ative para inserir informações teste</span> : <span>Desative para inserir informações reais</span>}
                                                        </FlowConfigItemDescription>
                                                    </FlowConfigItem> : <></>
                                                }

                                            </FlowConfigItems>
                                        </FlowConfigGroup>

                                        <FlowConfigGroup style={{ marginTop: '20px' }}>
                                            {getAccessControl(24, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ?
                                                <FlowConfigTitle>
                                                    Opções Avançadas
                                                </FlowConfigTitle> :
                                                <></>
                                            }

                                            <FlowConfigItems>

                                                {getAccessControl(24, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ?
                                                    <FlowConfigItem color="#e24d4b" onClick={dialogDelFlowOpen}>
                                                        <FlowConfigItemIcon color="#e24d4b">
                                                            <FiTrash2 />
                                                        </FlowConfigItemIcon>
                                                        <FlowConfigItemDescription>
                                                            <h3>Excluir o fluxo</h3>
                                                            <span>Cuidado! Exclua o fluxo e os seus dados</span>
                                                        </FlowConfigItemDescription>
                                                    </FlowConfigItem> : <></>
                                                }

                                            </FlowConfigItems>
                                        </FlowConfigGroup>

                                    </RightPanelList>
                                </RightPanelItems>

                            </RightPanel>

                            <ConfigCard
                                onClose={configCardClose}
                                open={openConfigCard}
                                flow={flow}
                            />
                            <DialogAction
                                open={stateDelFlow}
                                message={"Você tem certeza que deseja excluir este Fluxo?"}
                                actions_items={[
                                    "Exclusão de todos os cartões do fluxo;",
                                    "Exclusão de toda estrutura do fluxo",
                                    "Exclusão de todas as informações do fluxo;"
                                ]}
                                disclaimer={"Lembrando que após a confirmação não será mais possível recuperar os dados referentes ao Fluxo!"}
                                onSubmmit={dialogDelFlowSubmmit}
                                onClose={dialogDelFlowClose}
                                isLoading={loadingDeleteFlow}
                                type={1}
                            />
                        </> : <></>
                    }

                    {openIconColorPicker && getAccessControl(15, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") &&
                        <IconColorPicker
                            key={"icon-color-picker"}
                            open={openIconColorPicker}
                            icon={iconFlow}
                            color={colorFlow}
                            top={55}
                            left={10}
                            index={0}
                            handleClose={handleCloseIconColorPicker}
                            setColor={setColorFlow}
                            setIcon={setIconFlow}
                        />
                    }

                </AppBarHeader>

                {flow !== undefined && openAutomation && (
                    <Automation
                        open={openAutomation}
                        flow={flow}
                        onClose={() => setOpenAutomation(false)}
                    />
                )}

                <FilterBar
                    flow={flow}
                    itemNavBar={itemNavBarSelected}
                    typeUser={flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : ""}
                    onFilterFlow={onFilterFlow}
                    onClickField={onFilterField}
                    onAddElement={onAddElementDialog}
                    statusSaved={statusSavedPanel}
                    selectedView={selectedView}
                    initFilterFlow={initFilterFlow}
                    isArchived={isArchived}
                    panelId={panel !== undefined ? panel.id_panel : undefined}
                    onRemoveArchived={() => setArchiveMode(false)}
                    onSyncPanel={getApiPanelData}
                    loadingPanel={loadingPanel}
                />

                {/* Container Body */}
                <ContainerBody hideFilterBar={itemNavBarSelected === 3}>
                    <ContainerContent>

                        {flow !== undefined && !firstUpdate ?
                            <>
                                {itemNavBarSelected === 0 ?
                                    <Kanban
                                        key={"kanban"}
                                        flow={flow}
                                        loaderCard={loaderCard}
                                        openDetail={cardOpen}
                                        refreshPage={() => refreshPage()}
                                        configCard={() => configCardOpen()}
                                        isArchived={isArchived}
                                        testModel={testModelFlow}
                                        filterFlow={filterFlow}
                                        typeUser={flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : ""}
                                        updateControl={updateControl}
                                        setUpdateControl={setUpdateControl}
                                        printComponentRef={printComponentRef}
                                    /> :
                                    itemNavBarSelected === 1 ?
                                        <TableCange
                                            selectedValue={flow}
                                            onRowClicked={onRowClicked}
                                            testModel={testModelFlow}
                                            isArchived={isArchived}
                                            filterFlow={filterFlow}
                                            updateControl={updateControl}
                                            configCard={() => configCardOpen()}
                                            printComponentRef={printComponentRef}
                                            setUpdateControl={setUpdateControl}
                                            typeUser={flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : ""}
                                        /> :
                                        itemNavBarSelected === 2 ?
                                            <>
                                                <Dashboard
                                                    flow={flow}
                                                    panel={panel}
                                                    loading={loadingPanel}
                                                    typeUser={flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : ""}
                                                    onUpdatePanel={onUpdatePanel}
                                                    onUpdateStatusSavedPanel={onUpdateStatusSavedPanel}
                                                    onCopyElement={onCopyElement}
                                                    onEditElement={onEditElement}
                                                    loadPanel={getApiPanel}
                                                    loadData={getApiPanelData}
                                                    onSavePanel={savePanel}
                                                    printComponentRef={printComponentRef}
                                                    setPendingSaving={setPendingSavingPanel}
                                                    pendingSaving={pendingSavingPanel}
                                                />
                                                {openNewElement && (
                                                    <NewElement
                                                        flow={flow}
                                                        open={openNewElement}
                                                        isTestMode={testModelFlow}
                                                        onClose={() => {
                                                            setOpenNewElement(false);
                                                            setElementSelected(undefined);
                                                        }}
                                                        onAddNewElement={onAddNewElement}
                                                        element={elementSelected}
                                                    />
                                                )}
                                            </> : itemNavBarSelected === 3 ?
                                                <EmailBox
                                                    flow={flow}
                                                    typeUser={flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : ""}
                                                /> : itemNavBarSelected === 4 ?
                                                    <Calendar
                                                        selectedValue={flow}
                                                        onEventClicked={onRowClicked}
                                                        testModel={testModelFlow}
                                                        filterFlow={filterFlow}
                                                        updateControl={updateControl}
                                                        printComponentRef={printComponentRef}
                                                    /> : itemNavBarSelected === 5 && getAccessControl(74, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ?
                                                        <ActivityTab
                                                            flow_id={flow.id_flow}
                                                            onClickCard={onRowClicked}
                                                            isTestModel={testModelFlow}
                                                        /> :
                                                        <></>
                                }
                                {openCard && (
                                    <CardDialogContainer
                                        isMobile={isMobile}
                                        open={openCard}
                                        flow={flow}
                                        kanban_config={kabanConfig}
                                        selectedValue={currentCard}
                                        onClose={cardClose}
                                        testModel={testModelFlow}
                                        typeUser={flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : ""}
                                    />
                                )}
                            </> :
                            <></>

                        }

                    </ContainerContent>
                </ContainerBody>

            </ListContainer>
        </LayoutContainer>
    );
};

export default FlowList;
