import React, { useCallback, useEffect, useRef, useState } from "react";

import {
    Content,
    ContentBody,
    DialogTop,
    DialogTopLeft,
    DialogTopCenter,
    DialogTopRight,
    IconHeader,
    CleanBox,
    CleanBoxTitle,
    CleanBoxText,
    UploadMessage,
    DialogFooter,
    DialogFooterCenter,
    BtnInsertNewAnswer,
    ContainerRelationship,
    RelationshipItem,
    RelationshipItemLeft,
    RelationshipItemLeftAtive,
    RelationshipItemTitle,
    RelationshipItemCenter,
    RelationshipItemRight,
    SelectInput,
    OptionItem,
    HelpContainer,
    HelpItems,
    HelpItem,
    HelpIcon,
    HelpText,
    RelationshipHeader,
    RelationshipHeaderLeft,
    RelationshipHeaderCenter,
    RelationshipHeaderRight,
    RelationshipHeaderTitle,
    RelationshipHeaderSubTitle,
    ErrorContainer,
    TableContainer,
    DataTableCange,
    NoRegister,
    RegisterData,
    DialogFooterLeft,
    ContainerLoader,
    Animation,
    LoaderTitle,
    LoaderIcon,
    ContainerTypeImportFlow,
    BtnTypeImportFlowLeft,
    BtnTypeImportFlowRight
} from "./style";

import { Dialog } from '@material-ui/core';
import { FaPlus } from "react-icons/fa";
import { AiOutlineClose, AiOutlineCloseCircle } from "react-icons/ai";
import { TbDatabaseImport } from "react-icons/tb";
import Dropzone, { DropEvent, FileRejection } from "react-dropzone";
import { useToast } from "../../hooks/toast";
import Papa, { ParseError, ParseResult } from 'papaparse';
import api from "../../services/api";
import { ErrorFieldValidation, FieldProps, getFieldObject, validateField } from "../../components/Forms/Fields/FieldBuilder";
import { BsArrowLeftSquare } from "react-icons/bs";
import { IoWarningOutline } from "react-icons/io5";
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { styled } from '@material-ui/core/styles';
import { IDataTableColumn } from 'react-data-table-component';
import Title from "../../components/Title";
import lottie from 'lottie-web';
import { IoIosCheckmark, IoMdCheckmarkCircleOutline } from "react-icons/io";
import { Flow } from "../../interfaces/Flow";
import getFields from "../../utils/getFields";
import convertDMYtoDate from "../../utils/convertDMYtoDate";
import { User } from "../../interfaces/User";
import { Register } from "../../interfaces/Register";
import { FlowTag } from "../../interfaces/FlowTag";

export const MAX_SIZE_ATTACH = 10 * 1024 * 1024;

export const allowedMimes = [
    'text/csv'
];

interface objectInit {
    [x: string]: string | object | string[]
}

interface FieldsImportProps {
    field: FieldProps;
    index: number;
    active: boolean;
    id_origin?: number;
    origin?: string;
    reference?: string;
    errors?: ErrorFieldValidation[];
}

interface ImporterDataProps {
    open: boolean;
    form_id: number;
    register_id?: number;
    flow_id?: number;
    onClose: () => void;
}

interface ColumnSizeProps {
    id_field: number;
    width: number;
}

const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    "& .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom": {
        backgroundColor: "#1F2D32",
        color: 'white',
        boxShadow: theme.shadows[1],
        fontSize: 12,
    },
}));

const customStyles = {
    headRow: {
        style: {
            borderTop: 'solid 0.5px #e0e0e0'
        },
    },
    headCells: {
        style: {
            color: 'black',
            fontSize: '14px',
            //fontWeight: 400,
            borderBottom: 'solid 0.5px #e0e0e0',
            borderRight: 'solid 0.5px #e0e0e0',
            borderLeft: 'solid 0.5px #e0e0e0',

        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: '#f4f4f4',
            borderBottomColor: '#FFFFFF',
            outline: '1px solid white',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
    cells: {
        style: {
            borderRight: 'solid 0.5px #e0e0e0',
            borderLeft: 'solid 0.5px #e0e0e0'
        }
    },
};

function getColumnsConfig(fields: FieldsImportProps[], data: Record<string, string>[]): IDataTableColumn[] {

    let columnConfig: IDataTableColumn[] = [];
    let columnsSize: ColumnSizeProps[] = [];
    let sizeByPixel = 10;

    //Adjust the size of the columns
    for (let idxB = 0; idxB < fields.length; idxB++) {
        const field = fields[idxB];

        if (field.reference !== undefined) {
            for (let index = 0; index < data.length; index++) {
                const row = data[index];

                const val = row[field.reference];
                const maxSize = 400;
                const minSize = 175;

                if (val !== undefined) {
                    const sizeColumn = val.length * sizeByPixel;
                    const newColumnSize: ColumnSizeProps = {
                        id_field: field.index,
                        width: sizeColumn < minSize ? minSize : sizeColumn
                    }

                    const idx = columnsSize.findIndex((col) => col.id_field === field.index);
                    if (idx !== undefined && columnsSize[idx] !== undefined) { //Update a existing
                        if (columnsSize[idx].width < newColumnSize.width) { //If is bigger

                            //Control off size (min and max)
                            if (newColumnSize.width < maxSize) {
                                if (newColumnSize.width < minSize) {
                                    columnsSize[idx].width = minSize
                                } else {
                                    columnsSize[idx] = newColumnSize;
                                }
                            } else {
                                columnsSize[idx].width = maxSize;
                            }

                        }
                    } else { //Add a New
                        columnsSize.push(newColumnSize);
                    }
                }

            }
        }
    }

    //Fields Default
    const colNumber: IDataTableColumn = {
        id: -1,
        name: "Nº",
        cell: (row, index) => {
            return (
                index + 1
            )
        },
        minWidth: "40px",
        maxWidth: "60px",
        center: true,
    }
    columnConfig.push(colNumber);

    const colStatus: IDataTableColumn = {
        id: -2,
        name: "Status",
        cell: (row, index) => {

            let newErrors: ErrorFieldValidation[] = [];

            for (let idx = 0; idx < fields.length; idx++) {
                const f = fields[idx];

                const filterErrors = f.errors?.filter((err) => err.row === index + 1);
                if (filterErrors !== undefined && filterErrors.length > 0) {
                    newErrors.push(...filterErrors);
                }
            }

            return (
                newErrors !== undefined && newErrors.length > 0 ?
                    <NoRegister>
                        <DarkTooltip title={
                            <React.Fragment>
                                {newErrors.map((err, idx) => {
                                    return (
                                        <div key={idx} style={{ textAlign: 'center' }}>
                                            {err.message}
                                        </div>
                                    )
                                })}
                            </React.Fragment>
                        }>
                            <div>
                                <IoWarningOutline size={18} color="red" />
                            </div>
                        </DarkTooltip>
                    </NoRegister> :
                    <NoRegister>
                        <IoIosCheckmark size={18} color="green" />
                    </NoRegister>
            )
        },
        minWidth: "40px",
        maxWidth: "100px",
        center: true,
    }
    columnConfig.push(colStatus);

    if (fields !== undefined && fields.length > 0) {

        for (let index = 0; index < fields.length; index++) {

            const element = fields[index];
            const columnWidth = columnsSize?.filter((col) => col.id_field === element.index);

            const column: IDataTableColumn = {
                id: element.index,
                name: element.field.title.toString(),
                cell: (row) => {

                    let value = '';

                    if (element.reference !== undefined) {
                        value = row[element.reference];
                    }

                    return (
                        value === undefined || value.length <= 0 ?
                            <NoRegister>Sem registro</NoRegister> :
                            <RegisterData key={index}>{value}</RegisterData>
                    )
                },
                sortable: true,
                allowOverflow: false,
                minWidth: columnWidth !== undefined && columnWidth.length > 0 ? columnWidth[0].width + "px" : "175px",
            }

            if (fields[index].active === true) {
                columnConfig.push(column);
            }

        }

    }

    return columnConfig;

}

const ImporterData: React.FC<ImporterDataProps> = ({ onClose, open, form_id, register_id, flow_id }) => {

    const { addToast } = useToast();

    const [importStep, setImportStep] = useState<number>(0); //0 - Upload file, 1 - Mapping fields, 2 - View data
    const [importResult, setImportResult] = useState<number>(0); //0 - Failed import, 1 - Success import
    const [totalImportable, setTotalImportable] = useState<number>(0);
    const [loadingImport, setLoadingImport] = useState<boolean>(false);
    const [csvData, setCsvData] = useState<ParseResult<unknown>>();
    const [data, setData] = useState<Record<string, string>[]>();
    const [fieldsImport, setFieldsImport] = useState<FieldsImportProps[]>([]);
    const [errors, setErrors] = useState<ParseError[]>();
    const [columnConfig, setColumnConfig] = useState<IDataTableColumn[]>();
    const lottieContainer = useRef<HTMLDivElement>(null);
    const [subHeaderAlign] = useState('right');
    const [noHeader] = useState(true);
    const [typeFlowImport, setTypeFlowImport] = useState<number>(0); //0 - Formulário Inicial, 1 - Todos os Campos
    const [flow, setFlow] = useState<Flow>();
    const [users, setUsers] = useState<User[]>();
    const [flowTags, setFlowTags] = useState<FlowTag[]>();

    const getFieldsFormApi = useCallback(async (form_id: number) => {

        await api.get(`/field/by-form`, {
            params: {
                form_id: form_id
            }
        }).then(response => {
            if (response.data !== null) {

                let retFields: FieldProps[] = response.data;

                let retFieldsImport: FieldsImportProps[] = [];

                for (let index = 0; index < retFields.length; index++) {
                    let newField = retFields[index];

                    if (newField.type === "COMBO_BOX_REGISTER_FIELD" && newField.register_id !== undefined) {

                        api.get(`/register`, {
                            params: {
                                id_register: newField.register_id,
                                withAnswers: true
                            }
                        }).then(response => {
                            if (response.data !== null) {
                                const respStep: Register = response.data;
                                newField.register = respStep;
                            }
                        });

                    }

                    retFieldsImport.push({
                        field: newField,
                        index: newField.index,
                        active: true
                    });

                }

                retFieldsImport = retFieldsImport.filter((fi) => fi.field !== undefined && getFieldObject(fi.field.type).onImporterData);

                setFieldsImport(retFieldsImport);

            }
        })

    }, []);

    const getFieldsFlowApi = useCallback(async (flow_id: number, isJustFormInit: boolean) => {

        await api.get(`/flow`, {
            params: {
                id_flow: flow_id
            }
        }).then(response => {
            if (response.data !== null) {

                let retFlow: Flow = response.data;
                let retFields: FieldProps[] = []

                setFlow(retFlow);

                if (retFlow !== undefined && retFlow.form_init !== undefined) {
                    retFields = getFields(retFlow.form_init, retFlow.flow_steps);
                }

                if (retFields !== undefined && retFields.length > 0) {

                    let retFieldsImport: FieldsImportProps[] = [];

                    //Insert the fixed fields
                    //Step
                    retFieldsImport.push({
                        field: {
                            name: "-1",
                            title: "Etapa",
                            description: "Etapa",
                            type: "TEXT_SHORT_FIELD",
                            required: false,
                            index: -1
                        },
                        index: 0,
                        active: true,
                        origin: "Cartão",
                        id_origin: -1
                    });

                    //Tags
                    retFieldsImport.push({
                        field: {
                            name: "-2",
                            title: "Etiquetas",
                            description: "Etiquetas",
                            type: "TEXT_SHORT_FIELD",
                            required: false,
                            index: -2
                        },
                        index: 1,
                        active: true,
                        origin: "Cartão",
                        id_origin: -2
                    });

                    const lastIndex = retFieldsImport.length;

                    for (let idx = 0; idx < retFields.length; idx++) {
                        let field = retFields[idx];

                        let originField = "Formulário Inicial";
                        let fromFormInit = true;
                        const findStepField = retFlow.flow_steps.find((step) => step.form_id === field.form_id);
                        if (findStepField !== undefined) {
                            originField = findStepField.name;
                            fromFormInit = false;
                        }

                        if (!fromFormInit) {
                            field = {
                                ...field,
                                required: false
                            }
                        }

                        if (field.type === "COMBO_BOX_REGISTER_FIELD" && field.register_id !== undefined) {

                            api.get(`/register`, {
                                params: {
                                    id_register: field.register_id,
                                    withAnswers: true
                                }
                            }).then(response => {
                                if (response.data !== null) {
                                    const respStep: Register = response.data;
                                    field.register = respStep;
                                }
                            });

                        }

                        retFieldsImport.push({
                            field: field,
                            index: idx + lastIndex,
                            id_origin: field.form_id,
                            origin: originField,
                            active: true
                        });

                    }

                    if (isJustFormInit) {
                        retFieldsImport = retFieldsImport.filter((fi) => fi.origin === "Formulário Inicial" || fi.origin === "Cartão");
                    }

                    retFieldsImport = retFieldsImport.filter((fi) => fi.field !== undefined && getFieldObject(fi.field.type).onImporterData);

                    setFieldsImport(retFieldsImport);

                }

            }
        })

    }, []);

    const getUsersApi = useCallback(async () => {

        if (register_id !== undefined) {
            api.get(`/user/by-company`, {
            }).then(response => {
                if (response.data !== null) {
                    const respStep: User[] = response.data;
                    setUsers(respStep);
                }
            });
        } else {
            api.get(`/user/by-flow`, {
                params: {
                    form_id: form_id,
                }
            }).then(response => {
                if (response.data !== null) {
                    const respStep: User[] = response.data;
                    setUsers(respStep);
                }
            });
        }

    }, [form_id, register_id]);

    const getFlowTagsApi = useCallback(async () => {

        api.get(`/flow-tag/by-flow`, {
            params: {
                flow_id: flow_id
            }
        }).then(response => {
            if (response.data !== null) {

                let respStep: FlowTag[] = response.data;

                setFlowTags(respStep);

            }
        });

    }, [flow_id]);

    const handleClose = useCallback(async () => {
        onClose();
    }, [onClose]);

    const onUploadFile = useCallback((files: File[]) => {

        if (files !== undefined && files.length > 0) {

            Papa.parse(files[0], {
                complete: (result) => {

                    if (result.meta.fields !== undefined) {

                        if (result.errors.length > 0) {
                            setErrors(result.errors);
                        }

                        setCsvData(result);
                        setImportStep(1);

                    } else {

                        addToast({
                            type: 'error',
                            title: 'Erro ao carregar o arquivo!',
                            description: "Não foi possível identificar as colunas do arquivo!",
                        });

                    }

                },
                header: true, // Se a primeira linha for um cabeçalho
                skipEmptyLines: true, // Ignora linhas vazias
            });

        }

    }, [addToast]);

    const onUploadReject = useCallback((fileRejections: FileRejection[], event: DropEvent) => {

        if (fileRejections !== undefined && fileRejections.length > 0) {
            for (let index = 0; index < fileRejections.length; index++) {
                const rejection = fileRejections[index];

                for (let idxB = 0; idxB < rejection.errors.length; idxB++) {
                    const error = rejection.errors[idxB];

                    addToast({
                        type: 'error',
                        title: 'Erro ao carregar o arquivo!',
                        description: error.message + " (" + error.code + ")",
                    });

                }
            }
        }
    }, [addToast]);

    const handleCheckboxChange = (index: number) => {
        const updatedFieldsImport = [...fieldsImport];

        let newFieldImport = updatedFieldsImport.find((fi) => fi.index === index);

        if (newFieldImport !== undefined) {
            newFieldImport.active = !newFieldImport.active;

            if (!newFieldImport.active) {
                newFieldImport.errors = undefined;
            }

            const idx = updatedFieldsImport.findIndex((fi) => fi.index === index);
            updatedFieldsImport[idx] = newFieldImport;
            setFieldsImport(updatedFieldsImport);
        }
    };

    const handleReferenceChange = useCallback(async (index: number, reference: string) => {
        const updatedFieldsImport = [...fieldsImport];

        let newFieldImport = updatedFieldsImport.find((fi) => fi.index === index);

        if (newFieldImport !== undefined) {
            if (reference !== '-1') {

                newFieldImport.reference = reference;
                newFieldImport.errors = undefined;

                //Run a validation
                if (csvData !== undefined && csvData.data !== undefined) {
                    for (let idx = 0; idx < csvData?.data.length; idx++) {
                        const line = csvData?.data[idx] as unknown as Record<string, unknown>;

                        let newErrors: ErrorFieldValidation[] = await validateField(newFieldImport.field, line[reference], idx + 1);
                        let currentErrors: ErrorFieldValidation[] | undefined = newFieldImport.errors;

                        //Valid default fields (Step)
                        if (newFieldImport.field.name === "-1") {

                            //Valid if the step name exist into the flow
                            if (flow !== undefined && flow.flow_steps !== undefined) {
                                const findStep = flow?.flow_steps.find((step) => step.name === line[reference]);
                                if (findStep === undefined) {
                                    newErrors.push({
                                        type: "valueNotFound",
                                        row: idx + 1,
                                        message: "Não existe uma etapa com este nome"
                                    });
                                }
                            }

                        } else if (newFieldImport.field.name === "-2") {

                            if (flowTags !== undefined && line[reference] !== undefined && line[reference] !== null && line[reference] !== '') {

                                const tags = String(line[reference]).includes(',') ? String(line[reference]).split(',') : [String(line[reference])];

                                for (let idxB = 0; idxB < tags.length; idxB++) {
                                    const tag = tags[idxB].trim();
                                    const findTag = flowTags.find((ft) => String(ft.description).toLocaleLowerCase() === String(tag).toLocaleLowerCase());

                                    if (findTag === undefined) {
                                        newErrors.push({
                                            type: "valueNotFound",
                                            row: idx + 1,
                                            message: "Não existe uma etiqueta com este nome"
                                        });
                                    }
                                }
                            }

                        } else if (newFieldImport.field.type === "COMBO_BOX_USER_FIELD") {

                            if ((newFieldImport.field.required === undefined || newFieldImport.field.required === null || Boolean(newFieldImport.field.required) === false) && (line[reference] === undefined || line[reference] === null || line[reference] === '')) {

                            } else {
                                if (users !== undefined) {
                                    const findUser = users.find((user) => user.email === line[reference]);
                                    if (findUser === undefined) {
                                        newErrors.push({
                                            type: "valueNotFound",
                                            row: idx + 1,
                                            message: "Não existe um usuário disponível para seleção com este e-mail"
                                        });
                                    }
                                }
                            }

                        } else if (newFieldImport.field.type === "COMBO_BOX_REGISTER_FIELD") {

                            if ((newFieldImport.field.required === undefined || newFieldImport.field.required === null || Boolean(newFieldImport.field.required) === false) && (line[reference] === undefined || line[reference] === null || line[reference] === '')) {

                            } else {
                                //Try to find the id_form_answer 
                                if (newFieldImport.field.register !== undefined && newFieldImport.field.register.form_answers !== undefined) {

                                    const ids = String(line[reference]).includes(',') ? String(line[reference]).split(',') : [String(line[reference])];

                                    for (let idxB = 0; idxB < ids.length; idxB++) {
                                        const id = ids[idxB].trim();

                                        const findRegister = newFieldImport.field.register?.form_answers.find((ans) => String(ans.id_form_answer) === String(id));

                                        if (findRegister === undefined) {
                                            newErrors.push({
                                                type: "valueNotFound",
                                                row: idx + 1,
                                                message: "Não existe um registro disponível para seleção com este ID"
                                            });
                                        }

                                    }
                                }
                            }

                        }

                        if (currentErrors !== undefined && currentErrors.length > 0) {
                            newFieldImport.errors?.push(...newErrors);
                        } else {
                            newFieldImport.errors = newErrors;
                        }

                    }
                }

            } else {
                newFieldImport.reference = undefined;
                newFieldImport.errors = undefined;
            }

            //Update the updatedFieldsImport
            const idx = updatedFieldsImport.findIndex((fi) => fi.index === index);
            updatedFieldsImport[idx] = newFieldImport;

        }

        setFieldsImport(updatedFieldsImport);
    }, [csvData, fieldsImport, flow, users, flowTags]);

    const getValuesFromData = useCallback((data: Record<string, string>[], fields: FieldsImportProps[], importErrors?: ParseError[]): objectInit[] => {

        let newObjArray: objectInit[] = [];

        //Update total avaible to import
        if (data !== undefined) {

            for (let index = 0; index < data.length; index++) {
                const row = data[index];

                let isImportable = true;
                let newObjData: objectInit = {};

                //Valid the file errors
                if (importErrors !== undefined && importErrors.length > 0) {
                    //Filter the errors in this row
                    const findErrors = importErrors?.filter((err) => err.row === index + 1);
                    if (findErrors !== undefined && findErrors.length > 0) {
                        isImportable = false;
                    }
                }

                //Run into fields
                for (let idxA = 0; idxA < fields.length; idxA++) {
                    const field = fields[idxA];

                    if (field.reference !== undefined && field.active) {

                        //filter the errors in this row
                        const findErrors = field.errors?.filter((err) => err.row === index + 1);
                        if (findErrors !== undefined && findErrors.length > 0) {
                            isImportable = false;
                        }

                        if (isImportable) {

                            let value: string | object | string[] = row[field.reference];

                            //There is options
                            if (field.id_origin === -2) {

                                if (flowTags !== undefined && value !== undefined && value !== null && value !== '') {
                                    const tags = String(value).includes(',') ? String(value).split(',') : [String(value)];
                                    let newTagsId: string[] = [];
                                    for (let idxB = 0; idxB < tags.length; idxB++) {
                                        const tag = tags[idxB].trim();
                                        const findTag = flowTags.find((ft) => String(ft.description).toLocaleLowerCase() === String(tag).toLocaleLowerCase());
                                        if (findTag !== undefined) {
                                            newTagsId.push(String(findTag.id_flow_tag));
                                        }
                                    }

                                    value = newTagsId;
                                }

                            } else if (field.field.type === 'COMBO_BOX_FIELD' || field.field.type === 'RADIO_BOX_FIELD') {
                                if (field.field.options !== undefined) {

                                    const option = field.field.options.find((opt) => opt.label === value);
                                    if (option !== undefined) {
                                        value = option.value;
                                    }

                                }
                            } else if (field.field.type === 'CHECK_BOX_FIELD') {

                                //Valid if has more than one option in the valeu, divider is ;
                                const values = value.split(';');

                                let newValue: string[] = [];

                                for (let idxB = 0; idxB < values.length; idxB++) {
                                    const val = values[idxB];

                                    if (field.field.options !== undefined) {

                                        const option = field.field.options.find((opt) => opt.label === val);
                                        if (option !== undefined) {
                                            newValue.push(option.value);
                                        }

                                    }

                                }

                                value = newValue;

                            } else if (field.field.type === 'CURRENCY_FIELD' || field.field.type === 'NUMBER_FIELD') {

                                //Valid if just has one dot, if is true, replace the dot to comma
                                const countDot = value.split('.').length - 1;
                                if (countDot === 1) {
                                    value = value.replace(".", ",");
                                }

                                value = value.replace(".", "").replace(".", "").replace(".", "").replace(",", ".").replace(/[^0-9.-]+/g, "");
                                value = value === "" ? "0" : value;

                            } else if (field.field.type === 'PHONE_FIELD') {

                                value = value
                                    .replace(/\D/g, "")
                                    .replace(/(\d{2})(\d)/, "($1) $2")
                                    .replace(/(\d{5})(\d{4})(\d)/, "$1-$2");

                            } else if (field.field.type === 'DATE_PICKER_FIELD' || field.field.type === 'DUE_DATE_FIELD') {

                                if (value !== undefined && value !== null && value !== '') {
                                    if (value.length < 11 && value.includes('/')) { //DD/MM/YYYY
                                        value = convertDMYtoDate(value).toISOString();
                                    } else if (value.length < 17 && value.includes('/') && value.includes(':')) { //DD/MM/YYYY HH:MM
                                        value = convertDMYtoDate(value, true).toISOString();
                                    } else {
                                        value = new Date(value).toISOString();
                                    }
                                } else {
                                    value = '';
                                }

                            } else if (field.field.type === 'DOC_FIELD') {

                                if (value.length < 14) {
                                    value = value
                                        .replace(/\D/g, "")
                                        .replace(/(\d{3})(\d)/, "$1.$2")
                                        .replace(/(\d{3})(\d)/, "$1.$2")
                                        .replace(/(\d{3})(\d{1,2})/, "$1-$2")
                                        .replace(/(-\d{2})\d+?$/, "$1");
                                } else {
                                    value = value
                                        .replace(/\D/g, '')
                                        .substring(0, 14)
                                        .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})/, '$1.$2.$3/$4')
                                        .replace(/(\d{4})(\d)/, '$1-$2');
                                }

                            } else if (field.field.type === 'COMBO_BOX_USER_FIELD') {

                                if (users !== undefined) {
                                    const findUser = users.find((user) => user.email === value);
                                    if (findUser !== undefined) {
                                        value = String(findUser.id_user);
                                    }
                                }

                            } else if (field.field.type === 'COMBO_BOX_REGISTER_FIELD') {

                                const ids = String(value).includes(',') ? String(value).split(',') : [String(value)];

                                let newRegisterIds: string[] = [];
                                for (let idxB = 0; idxB < ids.length; idxB++) {
                                    const id = ids[idxB].trim();

                                    if (id !== undefined && id !== null) {
                                        newRegisterIds.push(id);
                                    }

                                }

                                value = newRegisterIds;

                            }

                            newObjData[field.field.name] = value;

                        }

                    }
                }

                if (isImportable) {
                    newObjArray.push(newObjData);
                }

            }

        }

        return newObjArray;

    }, [flowTags, users]);

    const handleImport = useCallback(async () => {

        if (register_id !== undefined && data !== undefined) {

            setImportStep(3);
            setLoadingImport(true);

            //Get the data to import
            const newValues = getValuesFromData(data, fieldsImport, errors);

            const apiObj = {
                id_form: form_id,
                origin: '/Register/ImporterData',
                values: newValues,
                register_id: register_id
            }

            await api.post(`/form/import-data-register`, apiObj)
                .then(response => {
                    if (response.data !== null) {

                        const result: boolean = response.data;

                        if (result) {
                            setImportResult(1);
                        } else {
                            setImportResult(0);
                        }

                    }
                }).catch(error => {
                    setImportResult(0);
                }).finally(() => {
                    setLoadingImport(false);
                });

        } else if (flow_id !== undefined && data !== undefined) {

            setImportStep(3);
            setLoadingImport(true);

            //Get the data to import
            const newValues = getValuesFromData(data, fieldsImport, errors)

            const apiObj = {
                id_flow: flow_id,
                origin: '/Flow/ImporterData',
                values: newValues
            }

            await api.post(`/flow/import-data-flow`, apiObj)
                .then(response => {
                    if (response.data !== null) {

                        const result: boolean = response.data;

                        if (result) {
                            setImportResult(1);
                        } else {
                            setImportResult(0);
                        }

                    }
                }).catch(error => {
                    setImportResult(0);
                }).finally(() => {
                    setLoadingImport(false);
                });

        }

    }, [form_id, register_id, data, errors, fieldsImport, flow_id, getValuesFromData]);

    const handleVisualization = useCallback(async () => {

        if (fieldsImport !== undefined && fieldsImport.length > 0) {

            //Valid if all required fields are mapped
            let allRequiredMapped = true;
            for (let index = 0; index < fieldsImport.length; index++) {
                const field = fieldsImport[index];

                if (field.field.required && field.reference === undefined) {
                    allRequiredMapped = false;
                    break;
                }

            }

            if (allRequiredMapped) {
                setImportStep(2);
            } else {
                addToast({
                    type: 'error',
                    title: 'Existem campos obrigatórios não mapeados!',
                    description: "Você precisa mapear todos os campos obrigatórios!",
                });
            }

        }



    }, [addToast, fieldsImport]);

    const getReferenceFirstLineExample = (reference: string): string => {

        if (csvData !== undefined && csvData.data !== undefined) {

            const data = csvData.data[0] as unknown as Record<string, unknown>;

            if (data !== undefined && data[reference] !== undefined) {
                return String(data[reference]);
            }

        }

        return "";
    };

    useEffect(() => {

        if (flow_id !== undefined && typeFlowImport === 0) {
            getFieldsFlowApi(flow_id, true);
        } else if (flow_id !== undefined && typeFlowImport === 1) {
            getFieldsFlowApi(flow_id, false);
        } else {
            getFieldsFormApi(form_id);
        }

        getUsersApi();
        getFlowTagsApi();

    }, [typeFlowImport, form_id, flow_id, getFieldsFlowApi, getFieldsFormApi, getUsersApi, getFlowTagsApi]);

    useEffect(() => {

        if (importStep !== undefined && importStep === 2) {

            if (csvData !== undefined && csvData.data !== undefined) {
                const newData = csvData.data as unknown[] as Record<string, string>[];
                const newConfigColumns = getColumnsConfig(fieldsImport, newData);

                setData(newData);
                setColumnConfig(newConfigColumns);
            }

        }

    }, [fieldsImport, data, importStep, csvData]);

    useEffect(() => {

        //Update total avaible to import
        if (data !== undefined) {

            let totalImportable = 0;

            for (let index = 0; index < data.length; index++) {

                let isImportable = true;

                for (let idx = 0; idx < fieldsImport.length; idx++) {
                    const field = fieldsImport[idx];

                    if (field.reference !== undefined && field.active) {

                        //filter the errors in this row
                        const findErrors = field.errors?.filter((err) => err.row === index + 1);
                        if (findErrors !== undefined && findErrors.length > 0) {
                            isImportable = false;
                        }

                    }
                }

                //Valid the file errors
                if (errors !== undefined && errors.length > 0) {
                    //Filter the errors in this row
                    const findErrors = errors?.filter((err) => err.row === index + 1);
                    if (findErrors !== undefined && findErrors.length > 0) {
                        isImportable = false;
                    }
                }

                if (isImportable) {
                    totalImportable++;
                }

            }

            setTotalImportable(totalImportable);

        }

    }, [fieldsImport, data, errors]);

    useEffect(() => {
        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../assets/lottie/loader.json')
            })
        }
    }, [loadingImport]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={open}
            onClose={handleClose}
        >
            <DialogTop>
                <DialogTopLeft>
                    <IconHeader color={"#795548"}>
                        <div>
                            <TbDatabaseImport />
                        </div>
                    </IconHeader>
                    <h1>
                        {"Importar dados"}
                    </h1>
                </DialogTopLeft>

                <DialogTopCenter />

                <DialogTopRight>
                    <button onClick={handleClose}><AiOutlineClose /></button>
                </DialogTopRight>
            </DialogTop>

            <Content>
                <ContentBody container>

                    {importStep === 0 ?
                        <Dropzone accept={allowedMimes} onDropAccepted={onUploadFile} maxSize={MAX_SIZE_ATTACH} onDropRejected={onUploadReject}>
                            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                <CleanBox
                                    {...getRootProps()}
                                    isDragActive={isDragActive}
                                    isDragReject={isDragReject}
                                >
                                    <input {...getInputProps()} />
                                    {
                                        !isDragActive ?
                                            <>
                                                <FaPlus />
                                                <CleanBoxTitle>
                                                    Clique aqui e importe dados a partir de um arquivo (.csv)
                                                </CleanBoxTitle>
                                                <CleanBoxText>
                                                    Aqui você pode importar dados em massa a partir de um arquivo, sem a necessidade de inserir manualmente seus dados.
                                                </CleanBoxText>
                                            </> :
                                            isDragReject ?
                                                <UploadMessage type="error">
                                                    Arquivo não suportado :(
                                                </UploadMessage> :
                                                <UploadMessage type="sucess">
                                                    Solte os arquivos aqui :)
                                                </UploadMessage>
                                    }
                                </CleanBox>
                            )}
                        </Dropzone> :

                        importStep === 1 ?
                            <ContainerRelationship>

                                {errors !== undefined && errors.length > 0 ?
                                    <HelpContainer color="#fe8c2d1e">
                                        <HelpItems>
                                            <HelpItem>
                                                <HelpIcon color="#fe8c2d">
                                                    <IoWarningOutline />
                                                </HelpIcon>
                                                <HelpText>
                                                    <b>{"Atenção: "}</b>{"ao importar o arquivo e analisar, encontramos "}<b>{errors?.length + " erros. "}</b>{"Sugerimos que você verifique o arquivo ou estes dados não serão importados."}
                                                </HelpText>
                                            </HelpItem>
                                        </HelpItems>
                                    </HelpContainer> :
                                    <></>
                                }

                                {flow_id !== undefined ?
                                    <ContainerTypeImportFlow>
                                        <BtnTypeImportFlowLeft active={typeFlowImport === 0} onClick={() => setTypeFlowImport(0)}>Formulário Inicial</BtnTypeImportFlowLeft>
                                        <BtnTypeImportFlowRight active={typeFlowImport === 1} onClick={() => setTypeFlowImport(1)}>Todos os Campos</BtnTypeImportFlowRight>
                                    </ContainerTypeImportFlow> :
                                    <></>
                                }

                                <RelationshipHeader>
                                    <RelationshipHeaderLeft>
                                        <RelationshipHeaderTitle>
                                            {flow_id !== undefined ? "Campos do fluxo" : "Campos do formulário"}
                                        </RelationshipHeaderTitle>
                                        <RelationshipHeaderSubTitle>
                                            Campos que serão preenchido com os dados do arquivo
                                        </RelationshipHeaderSubTitle>
                                    </RelationshipHeaderLeft>

                                    <RelationshipHeaderCenter >
                                        <BsArrowLeftSquare />
                                    </RelationshipHeaderCenter>

                                    <RelationshipHeaderRight>
                                        <RelationshipHeaderTitle>
                                            {"Colunas do arquivo"}
                                        </RelationshipHeaderTitle>
                                        <RelationshipHeaderSubTitle>
                                            Relacione as colunas do arquivo com os campos do formulário
                                        </RelationshipHeaderSubTitle>
                                    </RelationshipHeaderRight>
                                </RelationshipHeader>

                                {fieldsImport.sort((a, b) => a.index - b.index).map((fieldImport) => (
                                    <RelationshipItem key={fieldImport.index} active={fieldImport.active}>
                                        <RelationshipItemLeft>

                                            <RelationshipItemLeftAtive type="checkbox" checked={fieldImport.active} onChange={() => handleCheckboxChange(fieldImport.index)} />

                                            <RelationshipItemTitle>
                                                {fieldImport.field.title} {fieldImport.field.required ? <b>*</b> : ""} {fieldImport.origin !== undefined ? <span>({fieldImport.origin})</span> : ""}
                                                <span>{fieldImport.reference !== undefined ? getReferenceFirstLineExample(fieldImport.reference) : ""}</span>
                                            </RelationshipItemTitle>

                                        </RelationshipItemLeft>

                                        <RelationshipItemCenter>
                                            <BsArrowLeftSquare />
                                        </RelationshipItemCenter>

                                        <RelationshipItemRight>

                                            {csvData !== undefined && csvData.meta.fields !== undefined ?
                                                <SelectInput value={fieldImport.reference} onChange={(e) => handleReferenceChange(fieldImport.index, e.currentTarget.value)}>
                                                    <OptionItem key={-1} value={-1}>Selecione um campo</OptionItem>
                                                    {csvData.meta.fields.map((field) => {
                                                        return (
                                                            <OptionItem key={field} value={field}>{field}</OptionItem>
                                                        )
                                                    })}
                                                </SelectInput> :
                                                <></>
                                            }

                                            {fieldImport.errors !== undefined && fieldImport.errors.length > 0 ?
                                                <ErrorContainer>
                                                    <DarkTooltip title={
                                                        <React.Fragment>
                                                            <div>Encontramos erros de importação em {fieldImport.errors.length} linhas</div>
                                                        </React.Fragment>
                                                    }>
                                                        <div>
                                                            <IoWarningOutline color="red" />
                                                        </div>
                                                    </DarkTooltip>
                                                </ErrorContainer> :
                                                <></>
                                            }

                                        </RelationshipItemRight>
                                    </RelationshipItem>
                                ))}
                            </ContainerRelationship> :

                            importStep === 2 ?
                                <ContainerRelationship>

                                    <RelationshipHeader>
                                        <RelationshipHeaderLeft>
                                            <RelationshipHeaderTitle>
                                                {"Visualização dos dados"}
                                            </RelationshipHeaderTitle>
                                            <RelationshipHeaderSubTitle>
                                                Visualize como os dados serão importados
                                            </RelationshipHeaderSubTitle>
                                        </RelationshipHeaderLeft>
                                        <RelationshipHeaderRight>
                                        </RelationshipHeaderRight>
                                    </RelationshipHeader>

                                    <TableContainer>
                                        {columnConfig !== undefined && columnConfig.length > 0 && data !== undefined ?
                                            <DataTableCange
                                                columns={columnConfig}
                                                data={data}
                                                subHeaderAlign={subHeaderAlign}
                                                noHeader={noHeader}
                                                customStyles={customStyles}
                                                striped={true}
                                                onRowClicked={() => { }}
                                                highlightOnHover
                                                noDataComponent={<Title color='gray' title='Por enquanto, não temos nenhum registro por aqui. Clique no botão acima para adicionar :)' />}
                                                pointerOnHover
                                                sortServer={false}
                                            /> :
                                            <></>
                                        }
                                    </TableContainer>

                                </ContainerRelationship> :
                                importStep === 3 ?
                                    <ContainerRelationship>
                                        {loadingImport ?
                                            <ContainerLoader>
                                                <Animation className="lottieContainer" ref={lottieContainer} />
                                                <LoaderTitle>
                                                    Importando os seus dados...
                                                </LoaderTitle>
                                            </ContainerLoader > :
                                            importResult === 1 ?
                                                <ContainerLoader>
                                                    <LoaderIcon>
                                                        <IoMdCheckmarkCircleOutline color="#61bd4f" />
                                                    </LoaderIcon>
                                                    <LoaderTitle>
                                                        {"Dados importados com sucesso! "}  &#x1F64C;
                                                    </LoaderTitle>
                                                </ContainerLoader> :
                                                <ContainerLoader>
                                                    <LoaderIcon>
                                                        <AiOutlineCloseCircle color="#f23b5c" />
                                                    </LoaderIcon>
                                                    <LoaderTitle>
                                                        {"Ocorreu um erro ao importar os dados! "} &#x1F615;
                                                    </LoaderTitle>
                                                </ContainerLoader>
                                        }
                                    </ContainerRelationship> :
                                    <></>
                    }

                </ContentBody>

            </Content>

            {importStep === 1 || importStep === 2 ?
                <DialogFooter>
                    <DialogFooterLeft>
                        {totalImportable > 0 ? totalImportable + " registros disponíveis" : ""}
                    </DialogFooterLeft>
                    <DialogFooterCenter>
                        {importStep === 1 ?
                            <>
                                <BtnInsertNewAnswer color={"gray"} onClick={() => setImportStep(0)} style={{ marginRight: '15px' }}>
                                    Voltar
                                </BtnInsertNewAnswer>
                                <BtnInsertNewAnswer color={"#f23b5c"} onClick={() => handleVisualization()}>
                                    Visualizar dados
                                </BtnInsertNewAnswer>
                            </> :
                            importStep === 2 ?
                                <>
                                    <BtnInsertNewAnswer color={"gray"} onClick={() => setImportStep(1)} style={{ marginRight: '15px' }}>
                                        Voltar
                                    </BtnInsertNewAnswer>
                                    <BtnInsertNewAnswer color={"#f23b5c"} style={{ width: '200px' }} onClick={() => handleImport()}>
                                        Importar {totalImportable > 0 ? totalImportable : ""} registros
                                    </BtnInsertNewAnswer>
                                </> :
                                importStep === 3 && !loadingImport ?
                                    <>
                                        <BtnInsertNewAnswer color={"gray"} onClick={() => onClose()} style={{ marginRight: '15px' }}>
                                            Fechar
                                        </BtnInsertNewAnswer>
                                    </> :
                                    <></>
                        }
                    </DialogFooterCenter>
                </DialogFooter> :
                <></>
            }
        </Dialog >
    );

}

export default ImporterData;