import * as Yup from 'yup';

import {
    AksToAction,
    AnimationContainer,
    Body,
    BtnAction,
    BtnStart,
    Claim,
    ClaimDesc,
    ClaimIcon,
    Container,
    ContainerButton,
    ContainerClaim,
    ContainerInput,
    ContainerMobile,
    ContainerStart,
    ContainerStep,
    ContainerTopAction,
    Content,
    Disclaimer,
    Header,
    HeaderLeft,
    HeaderRight,
} from './styles';
import { Link, useHistory, useLocation } from 'react-router-dom';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import DegradeLine from '../../../components/DegradeLine';
import { FiMail } from "react-icons/fi";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import Input from '../../../components/Forms/Fields/Components/Input';
import api from '../../../services/api';
import getBrowser from '../../../utils/getBrowser';
import getOS from '../../../utils/getOS';
import getValidationErrors from '../../../utils/getValidationErrors';
import logo from '../../../assets/wendata-logo.png';
import logoMini from '../../../assets/wendata-mini-logo.png';
import { useRouteMatch } from 'react-router-dom';
import { useToast } from '../../../hooks/toast';
import { useMediaQuery } from 'react-responsive';
import { TbLayoutKanban } from 'react-icons/tb';
import { IoCardOutline } from 'react-icons/io5';

interface OnboardingProps {
    email: string;
}

interface RouteParams {
    hash?: string;
    url_from?: string;
}

const Onboarding: React.FC = () => {

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const formRef = useRef<FormHandles>(null);
    const { params } = useRouteMatch<RouteParams>();
    const history = useHistory();
    const { addToast } = useToast();
    const location = useLocation();
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = useCallback(async (data: OnboardingProps) => {

        const searchParams = new URLSearchParams(location.search);

        try {

            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
                email: Yup.string()
                    .required('O campo de e-mail é obrigatório! Preencha acima com o seu e-mail')
                    .email('O e-mail inserido é inválido! Verifique ou tente outro e-mail')
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            const dataApi = {
                status: "1",
                url_from: params.url_from,
                email: data.email,
                hash: params.hash,
                utm_id: searchParams.get('utm_id') || '',
                utm_source: searchParams.get('utm_source') || '',
                utm_medium: searchParams.get('utm_medium') || '',
                utm_campaign: searchParams.get('utm_campaign') || '',
                utm_term: searchParams.get('utm_term') || '',
                utm_content: searchParams.get('utm_content') || '',
                language: window.navigator.language,
                operating_system: getOS(window),
                browser: getBrowser(window),
                screen_width: window.screen.width,
                screen_height: window.screen.height,
            }

            setLoading(true);
            await api.post('/onboarding/', dataApi).then(response => {

                if (response.data.id_user !== undefined && response.data.id_user > 0) {
                    history.push('/login/' + data.email);

                    addToast({
                        type: 'info',
                        title: 'Você já possui uma conta',
                        description: 'Oba! Você já é um usuário, faça o seu login :)',
                    });
                } else if (response.data.id_invited_user !== undefined && response.data.id_invited_user > 0) {
                    history.push('/login/' + data.email);

                    addToast({
                        type: 'info',
                        title: 'Oba! Você já possui um convite ativo para o Cange!',
                        description: 'Verifique sua caixa de e-mail para confirmar seu cadastro e colaborar com seus colegas',
                    });
                } else if (response.data.id_onboarding) {
                    history.push('/onboarding-step-2/' + response.data.hash);
                } else {
                    history.push('/onboarding-step-2/' + response.data.hash);
                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                addToast({
                    type: 'error',
                    title: 'Erro ao salvar os dados!',
                    description: 'Ocorreu um erro ao tentar salvar os dados na base!',
                });
            });

        } catch (err) {
            setLoading(false);
            if (err instanceof Yup.ValidationError) {
                const errors = getValidationErrors(err);
                formRef.current?.setErrors(errors);
            }

            console.log(err);
        }
    }, [addToast, params.hash, params.url_from, history, location.search]);

    useEffect(() => {

        const searchParams = new URLSearchParams(location.search);

        const dataApi = {
            url_form: params.url_from,
            path_name: window.location.pathname,
            language: window.navigator.language,
            operating_system: getOS(window),
            browser: getBrowser(window),
            screen_width: window.screen.width,
            screen_height: window.screen.height,
            utm_id: searchParams.get('utm_id') || '',
            utm_source: searchParams.get('utm_source') || '',
            utm_medium: searchParams.get('utm_medium') || '',
            utm_campaign: searchParams.get('utm_campaign') || '',
            utm_term: searchParams.get('utm_term') || '',
            utm_content: searchParams.get('utm_content') || ''
        }

        api.post('/log-client-info', dataApi);

    }, [params.url_from, location.search]);

    useEffect(() => {

        //Set focus on the first input
        setTimeout(() => {
            if (formRef.current) {
                formRef.current.getFieldRef('email').focus();
            }
        }, 500);

    }, []);

    return (
        !isMobile ?
            <Container> {/* Web */}
                <title>Crie sua conta no Cange!</title>
                <ContainerTopAction>
                    <AksToAction>
                        Já tem uma conta?
                    </AksToAction>
                    <Link to="/" style={{ color: '#14aaf5', paddingLeft: '5px' }}>
                        <BtnAction>
                            Entre aqui
                        </BtnAction>
                    </Link>
                </ContainerTopAction>
                <Content>
                    <AnimationContainer>
                        <Header>
                            <HeaderLeft>
                                <h1>Crie sua conta em minutos</h1>
                            </HeaderLeft>
                            <HeaderRight>
                                <a href='https://www.cange.me'>
                                    <img className='logo-cange' src={logo} alt="Logo wendata" />
                                    <img className='logo-mini-cange' src={logoMini} alt="Logo wendata" />
                                </a>
                            </HeaderRight>

                        </Header>
                        <DegradeLine />
                        <Body>
                            <Form ref={formRef} onSubmit={handleSubmit}>
                                <ContainerStep>
                                    <h1>Inicie sua avaliação gratuita de 15 dias</h1>
                                    <ContainerStart>
                                        <ContainerInput>
                                            <Input name="email" icon={FiMail} placeholder="Digite aqui seu e-mail profissional" />
                                        </ContainerInput>
                                        <ContainerButton>
                                            <BtnStart type="submit" isLoading={loading}>Explorar o Cange</BtnStart>
                                        </ContainerButton>
                                    </ContainerStart>
                                </ContainerStep>

                                <ContainerClaim>
                                    <Claim>
                                        <ClaimIcon>
                                            <TbLayoutKanban />
                                        </ClaimIcon>
                                        <ClaimDesc>
                                            Digitalize o primeiro processo em somente 5 minutos
                                        </ClaimDesc>
                                    </Claim>

                                    <Claim>
                                        <ClaimIcon>
                                            <IoCardOutline />
                                        </ClaimIcon>
                                        <ClaimDesc>
                                            Não precisa cartão de crédito para começar
                                        </ClaimDesc>
                                    </Claim>
                                </ContainerClaim>
                                <Disclaimer>
                                    <p>Ao criar uma conta, você concorda com a nossa <a rel="noreferrer" href='https://www.cange.me/politica-de-privacidade' target='_blank'>Política de Privacidade</a> e <a rel="noreferrer" href='https://www.cange.me/termos-de-uso' target='_blank'>Termos de uso</a></p>
                                </Disclaimer>
                            </Form>
                        </Body>
                    </AnimationContainer>
                </Content>
            </Container> :
            <ContainerMobile> {/* Mobile */}
                <title>Crie sua conta no Cange!</title>
                <Content>
                    <AnimationContainer>
                        <Header>
                            <HeaderRight>
                                <a href='https://www.cange.me'>
                                    <img className='logo-cange' src={logo} alt="Logo wendata" />
                                    <img className='logo-mini-cange' src={logo} alt="Logo wendata" />
                                </a>
                            </HeaderRight>
                            <ContainerTopAction style={{ top: '13px', right: '15px' }}>
                                <Link to="/" style={{ color: '#14aaf5', paddingLeft: '5px' }}>
                                    <BtnAction style={{ backgroundColor: "transparent", width: '70px', height: '30px', color: '#9337ed', border: '1px solid #9337ed' }}>
                                        Entrar
                                    </BtnAction>
                                </Link>
                            </ContainerTopAction>
                        </Header>
                        <DegradeLine />
                        <Body>
                            <Form ref={formRef} onSubmit={handleSubmit}>
                                <ContainerStep>
                                    <h1>Inicie sua avaliação gratuita de 15 dias</h1>
                                    <ContainerStart>
                                        <ContainerInput>
                                            <Input name="email" icon={FiMail} placeholder="Digite aqui seu e-mail profissional" />
                                        </ContainerInput>
                                        <ContainerButton>
                                            <BtnStart type="submit" isLoading={loading}>Explorar o Cange</BtnStart>
                                        </ContainerButton>
                                    </ContainerStart>
                                </ContainerStep>
                                <ContainerClaim>
                                    <Claim>
                                        Crie o primeiro fluxo em só 5 minutos
                                    </Claim>
                                    <Claim>
                                        Não precisa cartão de crédito para começar
                                    </Claim>
                                </ContainerClaim>

                                <Disclaimer style={{ marginTop: '110px' }}>
                                    <p>Ao criar uma conta, você concorda com a nossa <a rel="noreferrer" href='https://www.cange.me/politica-de-privacidade' target='_blank'>Política de Privacidade</a> e <a rel="noreferrer" href='https://www.cange.me/termos-de-uso' target='_blank'>Termos de uso</a></p>
                                </Disclaimer>
                            </Form>
                        </Body>
                    </AnimationContainer>
                </Content>
            </ContainerMobile>
    );
}

export default Onboarding;